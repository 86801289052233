import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useAPI } from "../../../apiContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Quiz from "reactjs-quiz";
import PdfLibrary from "../PdfLibrary/PdfLibrary";

const ViewLevel2 = () => {
	const { databyEmail } = useAPI();
	const [Level2, setLevel2] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [videoNo, setVideoNo] = useState(0);
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/Level2`)
			.then((res) => res.json())
			.then((data) => {
				setLevel2(data);
			});
	}, [submitting]);
	const [Level2MCQ, setLevel2MCQ] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/Level2MCQ`)
			.then((res) => res.json())
			.then((data) => {
				setLevel2MCQ(data);
			});
	}, [submitting]);
	const quiz = Level2MCQ && {
		quizTitle: "Level 2 Quiz",
		quizSynopsis: "",
		questions: Level2MCQ,
	};
	const [videoUnlocked, setVideoUnlocked] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/userByLevel/${databyEmail?.url}`)
			.then((res) => res.json())
			.then((data) => {
				setVideoUnlocked(data?.level2Unlocked);
			});
	}, [databyEmail, submitting, videoNo]);
	const [open, setOpen] = useState(false);
	return (
		<>
			<Box sx={{ px: 2 }}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h4'
					gutterBottom>
					Level 2 Programs
				</Typography>
				{videoUnlocked?.length > 0 ? (
					<Grid container spacing={2} sx={{ justifyContent: "center" }}>
						<Grid item md={7} xs={12}>
							{Level2?.[videoNo]?.video?.trim() !== "" && (
								<ReactPlayer
									key={videoNo}
									className='borderColor react-player'
									style={{ borderRadius: 11, backgroundColor: "black" }}
									width='100%'
									height='100%'
									url={Level2?.[videoNo]?.video}
									controls
								/>
							)}
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold", py: 0.5 }}
								variant='h6'>
								{Level2?.[videoNo]?.title}
							</Typography>
							<Typography
								className='textColor'
								variant='body2'
								sx={{ whiteSpace: "pre-line" }}>
								{Level2?.[videoNo]?.description}
							</Typography>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									mt: 1,
								}}>
								<Button
									onClick={() =>
										setVideoNo(videoNo === 0 ? videoNo : videoNo - 1)
									}
									variant='contained'
									className='button border'
									sx={{
										mb: 2,
										px: 3,
										fontWeight: "bold",
										border: "2px solid",
										backgroundColor: "transparent",
										borderRadius: "25px",
									}}>
									Previous
								</Button>{" "}
								<Button
									onClick={() => setOpen(true)}
									variant='contained'
									className='button border'
									sx={{
										mb: 2,
										px: 3,
										fontWeight: "bold",
										border: "2px solid",
										backgroundColor: "transparent",
										borderRadius: "25px",
									}}>
									<PictureAsPdfIcon sx={{ mr: 1 }} /> PDF Library
								</Button>
								{videoUnlocked?.find(
									(video) => video?.id === Level2?.[videoNo + 1]?._id,
								)?.id === Level2?.[videoNo + 1]?._id ? (
									<Button
										onClick={() => {
											setVideoNo(
												Level2?.length - 1 === videoNo ? videoNo : videoNo + 1,
											);
										}}
										variant='contained'
										className='button border'
										sx={{
											mb: 2,
											px: 3,
											fontWeight: "bold",
											border: "2px solid",
											backgroundColor: "transparent",
											borderRadius: "25px",
										}}>
										Next
									</Button>
								) : (
									<Button
										onClick={() => {
											setVideoNo(
												Level2?.length - 1 === videoNo ? videoNo : videoNo + 1,
											);
											setSubmitting(true);
											axios
												.put(
													`https://americasolarpros.herokuapp.com/unlockContent/${databyEmail?.url}`,
													{
														id: Level2?.[videoNo + 1]?._id,
														title: Level2?.[videoNo + 1]?.title,
														level: "Level-2",
													},
												)
												.then(function (response) {
													setSubmitting(false);
													Swal.fire({
														icon: "success",
														title: "Video Unlocked",
														showConfirmButton: false,
														timer: 1500,
													});
												})
												.catch(function (error) {
													console.log(error);
												});
										}}
										variant='contained'
										className=' border'
										sx={{
											mb: 2,
											px: 3,
											fontWeight: "bold",
											border: "2px solid",
											backgroundColor: "red",
											borderRadius: "25px",
										}}>
										Unlock Next
									</Button>
								)}
							</Box>
						</Grid>

						<Grid item md={5} xs={12}>
							<Box
								sx={{
									maxHeight: "80vh",
									overflowY: "scroll",
									textAlign: "left",
								}}>
								{Level2?.map((n, i) => (
									<>
										{videoUnlocked?.find((video) => video?.id === n?._id)
											?.id !== n?._id ? (
											<Typography
												disabled
												sx={{
													p: 1,
													border: "1px solid",
													cursor: "no-drop",
													color: "red",
												}}>
												{n?.title}
											</Typography>
										) : (
											<Typography
												onClick={() => setVideoNo(i)}
												className={`textColor ${
													videoNo === i && "boldVideoTitle"
												}`}
												sx={{ p: 1, border: "1px solid", cursor: "pointer" }}>
												{n?.title}
											</Typography>
										)}
									</>
								))}
							</Box>
						</Grid>
					</Grid>
				) : (
					<Button
						onClick={() => {
							setSubmitting(true);
							axios
								.put(
									`https://americasolarpros.herokuapp.com/unlockContent/${databyEmail?.url}`,
									{
										id: Level2?.[0]?._id,
										title: Level2?.[0]?.title,
										level: "Level-2",
									},
								)
								.then(function (response) {
									setSubmitting(false);
									Swal.fire({
										icon: "success",
										title: "Video Unlocked",
										showConfirmButton: false,
										timer: 1500,
									});
								})
								.catch(function (error) {
									console.log(error);
								});
						}}
						variant='contained'
						className=' border'
						sx={{
							my: "auto",
							p: 3,
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "red",
							borderRadius: "25px",
						}}>
						Unlock Video
					</Button>
				)}

				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={!Level2 || submitting}>
					<CircularProgress color='inherit' />
				</Backdrop>
			</Box>
			{open && (
				<PdfLibrary
					open={open}
					setOpen={setOpen}
					category={"Level-2-Programs"}
				/>
			)}
			<Container sx={{ mb: 3.5 }}>
				<Quiz
					className='textColor'
					quiz={quiz}
					shuffle={true}
					onComplete={(result) => console.log(result)}
				/>
			</Container>
			<LinearProgress
				variant='determinate'
				sx={{ py: 1, position: "fixed", bottom: 0, width: "100% " }}
				value={(
					((videoUnlocked?.length || 0) / (Level2?.length || 0)) *
					100
				).toFixed(2)}
			/>
		</>
	);
};

export default ViewLevel2;
