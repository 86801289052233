import React, { useEffect } from "react";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	Container,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const UpdateGoalSettingWorkshop = () => {
	const { id } = useParams();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			title: "",
			video: "",
			description: "",
			submitTime: "",
		},
	});
	const [datas, setDatas] = useState({});
	useEffect(() => {
		axios
			.get(`https://americasolarpros.herokuapp.com/GoalSettingWorkshop/${id}`)
			.then((res) => {
				reset(res?.data);
				setDatas(res?.data);
			});
	}, [id, reset]);
	const onSubmit = ({ title, video, description }) => {
		const data = {
			title,
			video,
			description,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		setSubmitting(true);
		axios
			.put(
				`https://americasolarpros.herokuapp.com/editGoalSettingWorkshop/${id}`,
				data,
			)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: true,
					timer: 950000,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 2 }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'
				gutterBottom>
				Update Goal Setting Workshop
			</Typography>
			<Grid container spacing={2}>
				<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
					<form data-aos='fade-left' onSubmit={handleSubmit(onSubmit)}>
						<TextField
							required
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Title'
							{...register("title", { required: true })}
						/>
						<TextField
							required
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Description'
							multiline
							InputLabelProps={{
								shrink: true,
							}}
							rows={5}
							{...register("description", { required: true })}
						/>
						<TextField
							required
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Video Link'
							{...register("video", { required: true })}
						/>
						<Button
							type='submit'
							variant='contained'
							className='button border'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
							}}>
							Update
						</Button>
					</form>
				</Grid>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !datas?._id}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default UpdateGoalSettingWorkshop;
