import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Typography,
	CircularProgress,
	Backdrop,
	TableCell,
	TableRow,
	Table,
	TableHead,
	TableBody,
	Paper,
	TextField,
	Button,
} from "@mui/material";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import axios from "axios";
import DoneIcon from "@mui/icons-material/Done";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Note from "./Note";
import NoteAboutRecruter from "./NoteAboutRecruter";
import Payment from "./Payment";
import { useAPI } from "../../../../apiContext";
import Pagination from "../../../../Pagination/Pagination";
import Search from "../../../../Pagination/Search";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	maxWidth: "95vw",
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const CommissionsByRecruit = ({ user, openCom, setOpenCom }) => {
	const { databyEmail } = useAPI();
	const [submitting, setSubmitting] = useState(false);
	const [sells, setSells] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	// Function to handle page change
	const handlePageChange = (newPage, newItemsPerPage) => {
		setCurrentPage(newPage);
		if (newItemsPerPage !== undefined) {
			setItemsPerPage(newItemsPerPage);
		}
	};
	useEffect(() => {
		fetch(
			`https://americasolarpros.herokuapp.com/successfullSellsBy/invitedRecrutedBy/${user?.url}?page=${currentPage}&perPage=${itemsPerPage}${searchQuery}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setSells(data.data);
				setTotalItems(data?.pagination?.total);
			});
	}, [user, submitting, currentPage, itemsPerPage, searchQuery]);

	const [amounts, setAmounts] = useState();
	const [openNote, setOpenNote] = useState(false);
	const [openRecruterNote, setOpenRecruterNote] = useState(false);
	const [datas, setDatas] = useState();
	let count = 1;
	let inviterTotal = 0;
	sells?.forEach((element) => {
		inviterTotal += parseFloat(element?.paidAmount);
	});
	let inviterRecruterTotal = 0;
	sells?.forEach((element) => {
		inviterRecruterTotal += parseFloat(element?.recruterPaidAmount);
	});
	const [pay, setPay] = useState(false);
	return (
		<div>
			{databyEmail ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={openCom}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={openCom}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setOpenCom(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
									zIndex: "3",
								}}
							/>
							<Box sx={{ height: "95px", mx: 2 }}>
								<Typography
									variant='h4'
									className='textColor'
									sx={{ fontWeight: "bold" }}>
									Commissions
								</Typography>
								<Typography
									variant='body'
									className='textColor'
									sx={{ fontWeight: "bold" }}>
									Recruter Commission Total :- ${inviterRecruterTotal}
									{databyEmail?.role === "Admin" && (
										<>
											<br />
											Inviters Commission Total :- ${inviterTotal}
										</>
									)}
								</Typography>
							</Box>
							<Search
								setSearchQuery={setSearchQuery}
								searchFields={[
									"assignedTo",
									"customer",
									"customerPhone",
									"invitedBy",
									"paidOn",
									"paidStatus",
									"recruterPaidOn",
									"recruterPaidStatus",
									"soldOn",
								]}
							/>
							<Paper
								className='container'
								sx={{
									overflow: "auto",
									maxHeight: "calc(90vh - 95px)",
									maxWidth: "95vw",
								}}>
								<Table size='small' stickyHeader aria-label='sticky table'>
									<TableHead sx={{ th: { fontWeight: "bold" } }}>
										<TableRow>
											<TableCell align='center'>No</TableCell>
											<TableCell align='center'>Product</TableCell>
											{databyEmail?.role === "Admin" && (
												<>
													<TableCell align='center'>
														Potential Commission
													</TableCell>
													<TableCell align='center'>Customer</TableCell>
												</>
											)}
											{databyEmail?.role === "Admin" && (
												<TableCell align='center'>Customer Phone</TableCell>
											)}
											<TableCell align='center'>Invited By</TableCell>
											{databyEmail?.role === "Admin" && (
												<TableCell align='center'>Assigned To</TableCell>
											)}
											<TableCell align='center'>Closed On</TableCell>
											<TableCell align='center'>Commission Amount</TableCell>
											<TableCell align='center'>Status</TableCell>
											<TableCell align='center'>Paid On</TableCell>
											{databyEmail?.role === "Admin" && (
												<TableCell align='center'>Note</TableCell>
											)}
											<TableCell align='center'>Inviter Recruted By</TableCell>
											<TableCell align='center'>
												Recruiter Paid Amount
											</TableCell>
											<TableCell align='center'>
												Recruiter Paid Status
											</TableCell>
											<TableCell align='center'>Recruiter Paid On</TableCell>
											<TableCell align='center'>Recruiter Note</TableCell>
										</TableRow>
									</TableHead>
									{sells?.length > 0 ? (
										<TableBody sx={{ td: { py: 1 } }}>
											{sells?.map((sell, key) => (
												<TableRow
													key={key}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}>
													<TableCell align='center'>{count++}</TableCell>
													<TableCell align='center'>
														{sell?.product?.name}
													</TableCell>
													{databyEmail?.role === "Admin" && (
														<>
															<TableCell align='center'>
																{sell?.product?.potentialCommission}
															</TableCell>
															<TableCell align='center'>
																{sell?.customer}
															</TableCell>
														</>
													)}
													{databyEmail?.role === "Admin" && (
														<TableCell align='center'>
															{sell?.customerPhone}
														</TableCell>
													)}
													<TableCell align='center'>
														{sell?.invitedBy}
													</TableCell>
													{databyEmail?.role === "Admin" && (
														<TableCell align='center'>
															{Array.isArray(sell?.assignedTo)
																? sell?.assignedTo?.map((assignee) => (
																		<div key={assignee?.email}>
																			({assignee?.url})&nbsp;
																		</div>
																  ))
																: sell?.assignedTo || "N/A"}
														</TableCell>
													)}
													<TableCell align='center'>{sell?.soldOn}</TableCell>
													<TableCell align='center'>
														{databyEmail?.role === "Admin" ? (
															<>
																{sell?.paidStatus !== "Paid" ? (
																	<Box
																		sx={{
																			display: "flex",
																			justifyContent: "center",
																		}}>
																		<TextField
																			size='small'
																			sx={{ width: "100px" }}
																			fullWidth
																			label='$$$'
																			type='number'
																			defaultValue={sell?.paidAmount}
																			onChange={(e) =>
																				setAmounts(e.target.value)
																			}
																		/>
																		<Button
																			className='border'
																			onClick={() => {
																				setSubmitting(true);
																				axios
																					.put(
																						`https://americasolarpros.herokuapp.com/successfullSellsAmoutSet/${sell?._id}`,
																						{ amount: amounts },
																					)
																					.then(function (response) {
																						setSubmitting(false);
																						Swal.fire({
																							icon: "success",
																							title: "Successfully Updated",
																							showConfirmButton: true,
																							timer: 2500,
																						});
																					})
																					.catch(function (error) {
																						console.log(error);
																					});
																			}}
																			variant='contained'
																			sx={{
																				maxWidth: "37px !important",
																				ml: 0.2,
																			}}>
																			<DoneIcon />
																		</Button>
																	</Box>
																) : (
																	<Typography variant='p'>
																		${sell?.paidAmount}
																	</Typography>
																)}
															</>
														) : (
															<Typography variant='p'>
																${sell?.paidAmount}
															</Typography>
														)}
													</TableCell>
													<TableCell align='center'>
														{databyEmail?.role === "Admin" ? (
															<>
																{sell?.paidStatus === "Not Paid Yet" ? (
																	<Button
																		onClick={() => {
																			setSubmitting(true);
																			Swal.fire({
																				title: "Are you sure?",
																				text: "You won't be able to revert this!",
																				icon: "warning",
																				showCancelButton: true,
																				confirmButtonColor: "#3085d6",
																				cancelButtonColor: "#d33",
																				confirmButtonText: `Yes, Marked As Paid`,
																			}).then((result) => {
																				if (result.isConfirmed) {
																					axios
																						.put(
																							`https://americasolarpros.herokuapp.com/successfullSellsPaidStatus/${sell?._id}`,
																							{
																								status: "Paid",
																								time: new Date().toLocaleString(
																									"en-US",
																									{
																										timeZone:
																											"America/Los_Angeles",
																										year: "numeric",
																										month: "2-digit",
																										day: "2-digit",
																										hour: "2-digit",
																										minute: "2-digit",
																										second: "2-digit",
																										timeZoneName: "short",
																									},
																								),
																							},
																						)
																						.then(function (response) {
																							setSubmitting(false);
																							Swal.fire({
																								icon: "success",
																								title:
																									"Successfully Marked As Paid",
																								showConfirmButton: true,
																								timer: 2500,
																							});
																						})
																						.catch(function (error) {
																							console.log(error);
																						});
																				}
																			});
																		}}
																		className='sendButton'
																		type='submit'
																		variant='contained'
																		sx={{
																			minWidth: "150px",
																			borderRadius: "25px",
																		}}>
																		Mark as Paid
																	</Button>
																) : (
																	<Typography variant='p'>
																		{sell?.paidStatus || "N/A"}
																	</Typography>
																)}
															</>
														) : (
															<Typography variant='p'>
																{sell?.paidStatus || "N/A"}
															</Typography>
														)}
													</TableCell>
													<TableCell align='center'>
														{sell?.paidOn || "N/A"}
													</TableCell>
													{databyEmail?.role === "Admin" && (
														<TableCell align='center'>
															<Box sx={{ display: "flex" }}>
																<Button
																	onClick={() => {
																		setPay(true);
																		setDatas(sell?.invitedBy);
																	}}
																	className='button border'
																	sx={{
																		fontWeight: "bold",
																		border: "2px solid",
																		backgroundColor: "transparent",
																		borderRadius: "25px",
																		m: 0.5,
																	}}
																	variant='contained'>
																	$
																</Button>
																<Button
																	onClick={() => {
																		setOpenNote(true);
																		setDatas(sell);
																	}}
																	className='button border'
																	sx={{
																		fontWeight: "bold",
																		border: "2px solid",
																		backgroundColor: "transparent",
																		borderRadius: "25px",
																		m: 0.5,
																	}}
																	variant='contained'>
																	<NoteAddIcon />
																</Button>
															</Box>
														</TableCell>
													)}

													<TableCell align='center'>
														{sell?.invitedRecrutedBy}
													</TableCell>
													<TableCell align='center'>
														{databyEmail?.role === "Admin" ? (
															<>
																{sell?.recruterPaidStatus !== "Paid" ? (
																	<Box
																		sx={{
																			display: "flex",
																			justifyContent: "center",
																		}}>
																		<TextField
																			size='small'
																			sx={{ width: "100px" }}
																			fullWidth
																			label='$$$'
																			type='number'
																			defaultValue={sell?.recruterPaidAmount}
																			onChange={(e) =>
																				setAmounts(e.target.value)
																			}
																		/>
																		<Button
																			className='border'
																			onClick={() => {
																				setSubmitting(true);
																				axios
																					.put(
																						`https://americasolarpros.herokuapp.com/successfullSellsAmoutSetRecruter/${sell?._id}`,
																						{ amount: amounts },
																					)
																					.then(function (response) {
																						setSubmitting(false);
																						Swal.fire({
																							icon: "success",
																							title:
																								"Successfully Marked As Paid",
																							showConfirmButton: true,
																							timer: 2500,
																						});
																					})
																					.catch(function (error) {
																						console.log(error);
																					});
																			}}
																			variant='contained'
																			sx={{
																				maxWidth: "37px !important",
																				ml: 0.2,
																			}}>
																			<DoneIcon />
																		</Button>
																	</Box>
																) : (
																	<Typography variant='p'>
																		${sell?.recruterPaidAmount}
																	</Typography>
																)}
															</>
														) : (
															<Typography variant='p'>
																${sell?.recruterPaidAmount}
															</Typography>
														)}
													</TableCell>
													<TableCell align='center'>
														{databyEmail?.role === "Admin" ? (
															<>
																{sell?.recruterPaidStatus === "Not Paid Yet" ? (
																	<Button
																		onClick={() => {
																			setSubmitting(true);
																			Swal.fire({
																				title: "Are you sure?",
																				text: "You won't be able to revert this!",
																				icon: "warning",
																				showCancelButton: true,
																				confirmButtonColor: "#3085d6",
																				cancelButtonColor: "#d33",
																				confirmButtonText: `Yes, Marked As Paid`,
																			}).then((result) => {
																				if (result.isConfirmed) {
																					axios
																						.put(
																							`https://americasolarpros.herokuapp.com/successfullSellsRecruterPaidStatus/${sell?._id}`,
																							{
																								status: "Paid",
																								time: new Date().toLocaleString(
																									"en-US",
																									{
																										timeZone:
																											"America/Los_Angeles",
																										year: "numeric",
																										month: "2-digit",
																										day: "2-digit",
																										hour: "2-digit",
																										minute: "2-digit",
																										second: "2-digit",
																										timeZoneName: "short",
																									},
																								),
																							},
																						)
																						.then(function (response) {
																							setSubmitting(false);
																							Swal.fire({
																								icon: "success",
																								title:
																									"Successfully Marked As Paid",
																								showConfirmButton: true,
																								timer: 2500,
																							});
																						})
																						.catch(function (error) {
																							console.log(error);
																						});
																				}
																			});
																		}}
																		className='sendButton'
																		type='submit'
																		variant='contained'
																		sx={{
																			minWidth: "150px",
																			borderRadius: "25px",
																		}}>
																		Mark as Paid
																	</Button>
																) : (
																	<Typography variant='p'>
																		{sell?.recruterPaidStatus || "N/A"}
																	</Typography>
																)}
															</>
														) : (
															<Typography variant='p'>
																{sell?.recruterPaidStatus || "N/A"}
															</Typography>
														)}
													</TableCell>
													<TableCell align='center'>
														{sell?.recruterPaidOn || "N/A"}
													</TableCell>
													<TableCell align='center'>
														<Box sx={{ display: "flex" }}>
															<Button
																onClick={() => {
																	setPay(true);
																	setDatas(sell?.invitedRecrutedBy);
																}}
																className='button border'
																sx={{
																	fontWeight: "bold",
																	border: "2px solid",
																	backgroundColor: "transparent",
																	borderRadius: "25px",
																	m: 0.5,
																}}
																variant='contained'>
																$
															</Button>
															<Button
																onClick={() => {
																	setOpenRecruterNote(true);
																	setDatas(sell);
																}}
																className='button border'
																sx={{
																	fontWeight: "bold",
																	border: "2px solid",
																	backgroundColor: "transparent",
																	borderRadius: "25px",
																	m: 0.5,
																}}
																variant='contained'>
																<NoteAddIcon />
															</Button>
														</Box>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									) : (
										<TableHead sx={{ th: { fontWeight: "bold" } }}>
											<TableRow>
												<TableCell align='center'>N/A</TableCell>
												<TableCell align='center'>N/A</TableCell>
												<TableCell align='center'>N/A</TableCell>

												<TableCell align='center'>N/A</TableCell>
												<TableCell align='center'>N/A</TableCell>
												<TableCell align='center'>N/A</TableCell>
												<TableCell align='center'>N/A</TableCell>
												<TableCell align='center'>N/A</TableCell>
												<TableCell align='center'>N/A</TableCell>
												<TableCell align='center'>N/A</TableCell>
												<TableCell align='center'>N/A</TableCell>
												<TableCell align='center'>N/A</TableCell>
												{databyEmail?.role === "Admin" && (
													<>
														<TableCell align='center'>N/A</TableCell>
														<TableCell align='center'>N/A</TableCell>
														<TableCell align='center'>N/A</TableCell>
														<TableCell align='center'>N/A</TableCell>
														<TableCell align='center'>N/A</TableCell>
													</>
												)}
											</TableRow>
										</TableHead>
									)}
								</Table>
							</Paper>
							<Box
								sx={{
									mt: 1.5,
								}}>
								<Pagination
									totalItems={totalItems}
									currentPage={currentPage}
									itemsPerPage={itemsPerPage}
									onPageChange={handlePageChange}
								/>
							</Box>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !databyEmail}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{openNote && (
				<Note openNote={openNote} setOpenNote={setOpenNote} datas={datas} />
			)}
			{openRecruterNote && (
				<NoteAboutRecruter
					openRecruterNote={openRecruterNote}
					setOpenRecruterNote={setOpenRecruterNote}
					datas={datas}
				/>
			)}
			{pay && <Payment datas={datas} pay={pay} setPay={setPay} />}
		</div>
	);
};

export default CommissionsByRecruit;
