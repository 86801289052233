import {
	Autocomplete,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useAPI } from "../../../../apiContext";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	maxWidth: "95vw",
	maxHeight: "90vh",
	minWidth: "50vw",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const AssignedChange = ({ setAssingChange, assingChange }) => {
	const { allUsers } = useAPI();
	const { setSubmitting, user } = assingChange;
	const [value, setValue] = React.useState("N/A");
	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={assingChange}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}>
			<Fade in={assingChange}>
				<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
					<CancelIcon
						onClick={() => setAssingChange(false)}
						className='textColor'
						sx={{
							position: "fixed",
							top: "5px",
							right: "5px",
							cursor: "pointer",
						}}
					/>
					<Typography
						variant='h4'
						gutterBottom
						className='textColor'
						sx={{ fontWeight: "bold" }}>
						Assign Change
					</Typography>
					{allUsers?.length ? (
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Autocomplete
								sx={{
									minWidth: 308.031,
								}}
								multiple
								options={
									allUsers /* ?.filter(
								(f) =>
									f?.role === "Admin" ||
									f?.role === "Manager" ||
									f?.role === "Regional" ||
									f?.role === "Trainer" ||
									f?.level?.split("-")?.[1] >= 3,
							) */
								}
								getOptionLabel={(option) => `${option?.url} : ${option?.email}`}
								defaultValue={
									Array.isArray(user?.assignedTo) ? user?.assignedTo : []
								}
								onChange={(event, newValue) =>
									setValue(
										newValue?.map((v) => ({
											email: v?.email,
											name: v?.headline?.title,
											url: v?.url,
											phone: v?.phone,
										})),
									)
								}
								filterSelectedOptions
								renderInput={(params) => (
									<TextField {...params} placeholder='Users' />
								)}
							/>
							<Button
								onClick={() => {
									setSubmitting(true);
									axios
										.put(
											`https://americasolarpros.herokuapp.com/assigned/${user?._id}`,
											{
												assignedTo: value || user?.assignedTo,
											},
										)
										.then(function (response) {
											setSubmitting(false);
											Swal.fire({
												icon: "success",
												title: "Successfully Updated",
												showConfirmButton: false,
												timer: 1500,
											});
											setAssingChange(false);
										})
										.catch(function (error) {
											console.log(error);
										});
								}}>
								Assign
							</Button>
						</Box>
					) : (
						<CircularProgress color='inherit' />
					)}
				</Box>
			</Fade>
		</Modal>
	);
};

export default AssignedChange;
