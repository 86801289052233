import React, { useState } from "react";

const Details = ({ n }) => {
	const [seeMore, setSeeMore] = useState(false);
	return (
		<>
			{seeMore ? (
				<>
					{n?.description}{" "}
					<span
						onClick={() => setSeeMore(false)}
						style={{
							fontWeight: "bold",
							textDecoration: "underline",
							cursor: "pointer",
						}}>
						See Less
					</span>
				</>
			) : (
				<>
					{n?.description?.slice(0, 200)}....
					<span
						onClick={() => setSeeMore(true)}
						style={{
							fontWeight: "bold",
							textDecoration: "underline",
							cursor: "pointer",
						}}>
						See More
					</span>
				</>
			)}
		</>
	);
};

export default Details;
