import {
	CircularProgress,
	Backdrop,
	Button,
	Grid,
	InputLabel,
	TextField,
	Typography,
	Container,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import useDataByEmail from "../useDataByEmail";
const RequestTrainingCall = () => {
	const [data] = useDataByEmail();
	const { register, handleSubmit, reset } = useForm();
	const [submitting, setSubmitting] = useState(false);
	const onSubmit = ({ details }) => {
		const datas = {
			id: `Training${Math.floor(100000 + Math.random() * 900000)}`,
			details,
			userName: data?.url,
			name: data?.headline?.title,
			phone: data?.phone,
			email: data?.email,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
			status: "Pending",
		};
		setSubmitting(true);
		axios
			.post(`https://americasolarpros.herokuapp.com/RequestTrainingCall`, datas)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your Successfully Requested for Training Call",
					showConfirmButton: false,
					timer: 1500,
				});
				reset();
				window.location.replace("/dashboard");
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				className='textColor'
				variant='h6'
				sx={{ fontWeight: "bold", mb: 2 }}>
				Request Training Call
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item md={7} sm={12} xs={12} sx={{ mx: "auto" }}>
						<InputLabel htmlFor='input-with-icon-adornment'>
							Why you want to Request Training Call?
						</InputLabel>
						<TextField
							required
							type='text'
							multiline
							rows={5}
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							{...register("details", { required: true })}
						/>

						<Button type='submit' className='confirm' sx={{ px: 2 }}>
							Request Training Call
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default RequestTrainingCall;
