import {
	Backdrop,
	Badge,
	Button,
	CircularProgress,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import CommissionsByInvite from "./CommissionsByInvite";
import ChatIcon from "@mui/icons-material/Chat";
import Chat from "./Chat";
import { Box } from "@mui/system";
import CommissionsByRecruit from "./CommissionsByRecruit";
import Payment from "./Payment";
import ViewOthersProfile from "./ViewOthersProfile";
import FaceIcon from "@mui/icons-material/Face";
import io from "socket.io-client";
import RoleChnage from "./RoleChnage";
import { DataGrid } from "@mui/x-data-grid";
import Accesses from "./Accesses";
import Search from "../../../../Pagination/Search";
import Pagination from "../../../../Pagination/Pagination";
/* const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	bgcolor: "white",
	border: "2px solid",
	boxShadow: 24,
	p: 4,
	textAlign: "left",
}; */

const socket = io(`https://americasolarpros.herokuapp.com`);

const AllUsers = () => {
	/* const [openLevel, setOpenLevel] = React.useState(false);
	const handlesetOpenLevel = () => setOpenLevel(true);
	const handlesetCloseLevel = () => setOpenLevel(false);
	const [userLevel, setUserLevel] = React.useState(); */
	const [deleted, setDeleted] = useState(false);
	const [room, setRoom] = useState();
	const [forCount, setForCount] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [datas, setDatas] = useState();
	const [openProfile, setOpenProfile] = useState();
	const [openChat, setOpenChat] = useState(false);
	const [lastLogged, setLastLogged] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/lastVisited`)
			.then((res) => res.json())
			.then((data) => {
				setLastLogged(data);
			});
	}, [submitting]);
	const [pay, setPay] = useState(false);
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/invitatedusers`)
			.then((res) => res.json())
			.then((data) => {
				setForCount(data?.data);
			});
	}, []);

	const [users, setUsers] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	// Function to handle page change
	const handlePageChange = (newPage, newItemsPerPage) => {
		setCurrentPage(newPage);
		if (newItemsPerPage !== undefined) {
			setItemsPerPage(newItemsPerPage);
		}
	};
	useEffect(() => {
		fetch(
			`https://americasolarpros.herokuapp.com/allusers?page=${currentPage}&perPage=${itemsPerPage}${searchQuery}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data.data);
				setTotalItems(data?.pagination?.total);
			});
	}, [deleted, submitting, openChat, currentPage, itemsPerPage, searchQuery]);

	const handleDelete = (id) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(`https://americasolarpros.herokuapp.com/deleteUser/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That user has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	const handleStatus = (id, status) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: `Yes, ${status}  it!`,
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.put(`https://americasolarpros.herokuapp.com/updateStatus/${id}`, {
						status: status,
					})
					.then(function (response) {
						setDeleted(false);
						Swal.fire({
							icon: "success",
							title: "Successfully Updated",
							showConfirmButton: false,
							timer: 1500,
						});
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	const [sells, setSells] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/successfullSells`)
			.then((res) => res.json())
			.then((data) => {
				setSells(data?.data);
			});
	}, []);
	let inviterTotal = 0;
	sells?.forEach((element) => {
		inviterTotal += parseFloat(element?.paidAmount);
	});
	let inviterRecruterTotal = 0;
	sells?.forEach((element) => {
		inviterRecruterTotal += parseFloat(element?.recruterPaidAmount);
	});
	const [openCom, setOpenCom] = useState(false);
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState();
	const [chats, setChats] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/chats`)
			.then((res) => res.json())
			.then((data) => {
				setChats(data);
			});
	}, [openChat, user, open]);

	/* 	const [Level1, setLevel1] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/Level1`)
			.then((res) => res.json())
			.then((data) => {
				setLevel1(data);
			});
	}, []);
	const [Level2, setLevel2] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/Level2`)
			.then((res) => res.json())
			.then((data) => {
				setLevel2(data);
			});
	}, []);
	const [Level3, setLevel3] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/Level3`)
			.then((res) => res.json())
			.then((data) => {
				setLevel3(data);
			});
	}, []); */
	const [access, setAccess] = useState(false);
	const columns = [
		{
			field: "title",
			headerName: "Name",
			minWidth: 200,
			valueGetter: (params) => params?.row?.headline?.title,
		},
		{
			field: "url",
			headerName: "Username",
			minWidth: 100,
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: 220,
		},
		{
			field: "phone",
			headerName: "Phone",
			minWidth: 120,
			valueGetter: (params) => params?.row?.phone,
			renderCell: (params) => {
				return (
					<a
						href={`tel:${params?.row?.phone}`}
						className='textColor'
						style={{ textDecoration: "none" }}>
						{params?.row?.phone}
					</a>
				);
			},
		},
		{
			field: "Role",
			headerName: "Role",
			minWidth: 150,
			filterable: false,
			renderCell: (params) => {
				return <RoleChnage user={params?.row} setSubmitting={setSubmitting} />;
			},
		},
		/* {
			field: "LevelChange",
			headerName: "Level",
			minWidth: 170,
			filterable: false,
			renderCell: (params) => {
				return (
					<LevelChange
						user={params?.row}
						setSubmitting={setSubmitting}
						handlesetOpenLevel={handlesetOpenLevel}
						setUserLevel={setUserLevel}
					/>
				);
			},
		}, */
		{
			field: "Access",
			headerName: "Access",
			minWidth: 100,
			filterable: false,
			renderCell: (params) => {
				return (
					<Button
						onClick={() => {
							setAccess(true);
							setUser(params?.row);
						}}>
						Open
					</Button>
				);
			},
		},

		{
			field: "lastVisitedTime",
			headerName: "Last Logged In",
			minWidth: 220,
			filterable: false,
			renderCell: (params) => {
				return lastLogged?.find((u) => u?.userName === params?.row?.url)
					?.lastVisited;
			},
		},
		{
			field: "joiningTime",
			headerName: "Joining Time",
			minWidth: 220,
		},
		{
			field: "recuretedBy",
			headerName: "Recureted By",
			minWidth: 120,
		},
		{
			field: "AppoinmentCount",
			headerName: "Appoinment Count",
			minWidth: 100,
			filterable: false,
			renderCell: (params) => {
				return (
					forCount?.filter((item) => item.invitationCode === params?.row?.url)
						?.length || 0
				);
			},
		},
		{
			field: "AppoinmentSuccess",
			headerName: "Appoinment Success",
			minWidth: 100,
			filterable: false,
			renderCell: (params) => {
				return (
					<Badge
						color={
							sells?.filter(
								(item) =>
									item.invitedBy === params?.row?.url &&
									item?.paidStatus !== "Paid",
							)?.length === 0
								? "success"
								: "error"
						}
						badgeContent={
							sells?.filter(
								(item) =>
									item.invitedBy === params?.row?.url &&
									item?.paidStatus !== "Paid",
							)?.length || 0
						}>
						<Button
							onClick={() => {
								setOpen(true);
								setUser(params?.row);
							}}>
							{sells?.filter((item) => item.invitedBy === params?.row?.url)
								?.length || 0}
						</Button>
					</Badge>
				);
			},
		},
		{
			field: "RecruitCount",
			headerName: "Recruit Count",
			minWidth: 100,
			filterable: false,
			renderCell: (params) => {
				return (
					users?.filter((item) => item.recuretedBy === params?.row?.url)
						?.length || 0
				);
			},
		},
		{
			field: "RecruitBonusCount",
			headerName: "Recruit Bonus Count",
			minWidth: 100,
			filterable: false,
			renderCell: (params) => {
				return (
					<Badge
						color={
							sells?.filter(
								(item) =>
									item?.invitedRecrutedBy === params?.row?.url &&
									item?.recruterPaidStatus !== "Paid",
							)?.length === 0
								? "success"
								: "error"
						}
						badgeContent={
							sells?.filter(
								(item) =>
									item?.invitedRecrutedBy === params?.row?.url &&
									item?.recruterPaidStatus !== "Paid",
							)?.length || 0
						}>
						<Button
							onClick={() => {
								setOpenCom(true);
								setUser(params?.row);
							}}>
							{sells?.filter(
								(item) => item?.invitedRecrutedBy === params?.row?.url,
							)?.length || 0}
						</Button>
					</Badge>
				);
			},
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 470,
			filterable: false,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex" }} className='online'>
						<Button
							onClick={() => {
								setPay(true);
								setDatas(params?.row?.url);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							$
						</Button>
						<Button
							onClick={() => {
								setDatas(params?.row);
								setOpenProfile(true);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<FaceIcon />
						</Button>
						<Badge
							color={
								chats &&
									chats?.find((u) => u?.chatRoom === params?.row?.url)?.status ===
									"Online"
									? "success"
									: "error"
							}
							badgeContent={
								chats
									?.find((u) => u?.chatRoom === params?.row?.url)
									?.chats?.filter((d) => d?.adminSeen === false)?.length
							}>
							<Button
								onClick={() => {
									setOpenChat(true);
									setUser(params?.row);
									setRoom(params?.row?.url);
									socket.emit("join_room", params?.row?.url);
								}}
								className=' border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<ChatIcon />
							</Button>
						</Badge>

						{params?.row?.status === "Active" && (
							<Button
								onClick={() =>
									window.open(`/@${params?.row?.url}`, "_blank").focus()
								}
								className='button border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<LanguageIcon />
							</Button>
						)}
						{params?.row?.role === "Admin" ? (
							<Button
								disabled
								className=' border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "gray !important",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<DeleteIcon />
							</Button>
						) : (
							<Button
								className='button border'
								onClick={() => handleDelete(params?.row?._id)}
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<DeleteIcon />
							</Button>
						)}
						{params?.row?.role === "Admin" ? (
							<Button
								disabled
								className=' border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "gray !important",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<CloseIcon />
							</Button>
						) : (
							<>
								{params?.row?.status === "Inactive" ? (
									<Button
										className='button border'
										onClick={() => handleStatus(params?.row?._id, "Active")}
										sx={{
											fontWeight: "bold",
											border: "2px solid",
											backgroundColor: "green !important",
											borderRadius: "25px",
											m: 0.5,
										}}
										variant='contained'>
										<DoneIcon />
									</Button>
								) : (
									<Button
										className='button border'
										onClick={() => handleStatus(params?.row?._id, "Inactive")}
										sx={{
											fontWeight: "bold",
											border: "2px solid",
											backgroundColor: "#ef1a1a !important",
											borderRadius: "25px",
											m: 0.5,
										}}
										variant='contained'>
										<CloseIcon />
									</Button>
								)}
							</>
						)}
					</Box>
				);
			},
		},
	];
	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				All Users
			</Typography>
			<Typography className='textColor'>
				Recruiter Commission Total :- ${inviterRecruterTotal}
				<br />
				Inviters Commission Total :- ${inviterTotal}
			</Typography>
			{users && (
				<Box sx={{ width: "100%", height: "75vh" }}>
					<DataGrid
						rows={users}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: () => (
								<Search
									setSearchQuery={setSearchQuery}
									searchFields={[
										"email",
										"headline.title",
										"joiningTime",
										"phone",
										"recuretedBy",
										"role",
										"status",
										"url",
									]}
								/>
							),
							Footer: () => (
								<Pagination
									totalItems={totalItems}
									currentPage={currentPage}
									itemsPerPage={itemsPerPage}
									onPageChange={handlePageChange}
								/>
							),
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!users}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{access && (
				<Accesses
					open={access}
					setOpen={setAccess}
					user={user}
					setSubmitting={setSubmitting}
				/>
			)}
			{open && (
				<CommissionsByInvite open={open} setOpen={setOpen} user={user} />
			)}
			{openProfile && (
				<ViewOthersProfile
					open={openProfile}
					setOpen={setOpenProfile}
					user={datas}
				/>
			)}
			{openCom && (
				<CommissionsByRecruit
					openCom={openCom}
					setOpenCom={setOpenCom}
					user={user}
				/>
			)}
			{openChat && (
				<Chat
					openChat={openChat}
					setOpenChat={setOpenChat}
					user={user}
					socket={socket}
					room={room}
				/>
			)}
			{pay && <Payment datas={datas} pay={pay} setPay={setPay} />}
			{/* <Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={openLevel}
				onClose={handlesetCloseLevel}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={openLevel}>
					<Box sx={style}>
						<Typography
							className='textColor'
							sx={{ fontWeight: "bold" }}
							variant='h5'>
							Level : {userLevel?.level || "No-Level"}
						</Typography>
						<Typography
							className='textColor'
							sx={{ fontWeight: "bold" }}
							variant='h6'>
							Level 1 :{" "}
							{(
								((userLevel?.level1Unlocked?.length || 0) /
									(Level1?.length || 0)) *
								100
							).toFixed(2)}{" "}
							%
						</Typography>
						<Typography
							className='textColor'
							sx={{ fontWeight: "bold" }}
							variant='h6'>
							Level 2 :{" "}
							{(
								((userLevel?.level2Unlocked?.length || 0) /
									(Level2?.length || 0)) *
								100
							).toFixed(2)}{" "}
							%
						</Typography>
						<Typography
							className='textColor'
							sx={{ fontWeight: "bold" }}
							variant='h6'>
							Level 3 :{" "}
							{(
								((userLevel?.level3Unlocked?.length || 0) /
									(Level3?.length || 0)) *
								100
							).toFixed(2)}{" "}
							%
						</Typography>
					</Box>
				</Fade>
			</Modal> */}
		</Box>
	);
};

export default AllUsers;
