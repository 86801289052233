import {
	Autocomplete,
	Backdrop,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const TeamManage = () => {
	const [regionTeam, setRegionTeam] = useState();
	const [managerTeam, setManagerTeam] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [selectRegion, setSelectRegion] = useState();
	const [regions, setRegions] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/regions`)
			.then((res) => res.json())
			.then((data) => {
				setRegions(data);
			});
	}, [submitting]);
	const [managers, setManagers] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/managers`)
			.then((res) => res.json())
			.then((data) => {
				setManagers(data);
			});
	}, [submitting]);
	const [selectmanager, setSelectManager] = useState();
	const [teamLead, setTeamLead] = useState();
	const [teamLeads, setTeamLeads] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/teamLeads`)
			.then((res) => res.json())
			.then((data) => {
				setTeamLeads(data);
			});
	}, [submitting]);
	const [selectTeamLead, setSelectTeamLead] = useState();
	const [trainer, setTrainer] = useState();
	const [trainers, setTrainers] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/trainers`)
			.then((res) => res.json())
			.then((data) => {
				setTrainers(data);
			});
	}, [submitting]);
	/////////////
	const [selectRegionTree, setSelectRegionTree] = useState();
	const [selectManagerTree, setSelectManagerTree] = useState();
	const [selectTeamLeadTree, setSelectTeamLeadTree] = useState();
	/* 	const [selectTrainerTree, setSelectTrainerTree] = useState(); */
	const [users, setUsers] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/allusers`)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data.data);
			});
	}, [submitting]);
	const [value, setValue] = useState([]);
	////////////
	console.log(value);
	return (
		<Box sx={{ p: 2 }}>
			<Box sx={{ display: "flex", mb: 1.5 }}>
				<TextField
					size='small'
					id='outlined-basic'
					onChange={(e) => setRegionTeam(e.target.value)}
					label='Region Team Name'
				/>
				<Button
					sx={{
						width: "100px",
						pointerEvents: regionTeam ? "auto" : "none",
						opacity: regionTeam ? "1" : "0.5",
					}}
					className='sendButton'
					type='submit'
					variant='contained'
					onClick={() => {
						setSubmitting(true);
						axios
							.post(`https://americasolarpros.herokuapp.com/addTeamOfRegion`, {
								regionTeam,
								type: "Region",
								teamLeaders: [],
							})
							.then(function (response) {
								setSubmitting(false);
								Swal.fire({
									icon: "success",
									title: "Successfully Added",
									showConfirmButton: true,
									timer: 1500,
								});
							})
							.catch(function (error) {
								console.log(error);
							});
					}}>
					Add
				</Button>
			</Box>
			<Box sx={{ display: "flex", my: 1.5 }}>
				<FormControl size='small'>
					<InputLabel>Select Region</InputLabel>
					<Select
						label='Select Region'
						sx={{ minWidth: "170px" }}
						size='small'
						value={selectRegion?.regionTeam}
						onChange={(e) => setSelectRegion(e.target.value)}>
						{regions?.map((r) => (
							<MenuItem value={r}>{r?.regionTeam}</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					size='small'
					id='outlined-basic'
					onChange={(e) => setManagerTeam(e.target.value)}
					label='Manager Team Name'
				/>
				<Button
					className='sendButton'
					type='submit'
					variant='contained'
					sx={{
						width: "100px",
						pointerEvents: selectRegion && managerTeam ? "auto" : "none",
						opacity: selectRegion && managerTeam ? "1" : "0.5",
					}}
					onClick={() => {
						setSubmitting(true);
						axios
							.post(`https://americasolarpros.herokuapp.com/addTeamOfManager`, {
								underRegionTeam: selectRegion?.regionTeam,
								type: "Manager",
								managerTeam,
								teamLeaders: [],
							})
							.then(function (response) {
								setSubmitting(false);
								Swal.fire({
									icon: "success",
									title: "Successfully Added",
									showConfirmButton: true,
									timer: 1500,
								});
							})
							.catch(function (error) {
								console.log(error);
							});
					}}>
					Add
				</Button>
			</Box>
			<Box sx={{ display: "flex", my: 1.5 }}>
				<FormControl size='small'>
					<InputLabel>Select Region</InputLabel>
					<Select
						label='Select Region'
						sx={{ minWidth: "170px" }}
						size='small'
						value={selectRegion?.regionTeam}
						onChange={(e) => setSelectRegion(e.target.value)}>
						{regions?.map((r) => (
							<MenuItem value={r}>{r?.regionTeam}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl size='small'>
					<InputLabel>Select Manager</InputLabel>
					<Select
						label='Select Manager'
						sx={{ minWidth: "170px" }}
						size='small'
						value={selectmanager?.managerTeam}
						onChange={(e) => setSelectManager(e.target.value)}>
						{managers
							?.filter((f) => f?.underRegionTeam === selectRegion?.regionTeam)
							?.map((r) => (
								<MenuItem value={r}>{r?.managerTeam}</MenuItem>
							))}
					</Select>
				</FormControl>
				<TextField
					size='small'
					id='outlined-basic'
					onChange={(e) => setTeamLead(e.target.value)}
					label='Team Lead Name'
				/>
				<Button
					className='sendButton'
					type='submit'
					variant='contained'
					sx={{
						width: "100px",
						pointerEvents:
							selectRegion && selectmanager && teamLead ? "auto" : "none",
						opacity: selectRegion && selectmanager && teamLead ? "1" : "0.5",
					}}
					onClick={() => {
						setSubmitting(true);
						axios
							.post(`https://americasolarpros.herokuapp.com/addTeamOfTeamLeads`, {
								underRegionTeam: selectRegion?.regionTeam,
								underManagerTeam: selectmanager?.managerTeam,
								teamLead,
								type: "Team-Lead",
								teamLeaders: [],
							})
							.then(function (response) {
								setSubmitting(false);
								Swal.fire({
									icon: "success",
									title: "Successfully Added",
									showConfirmButton: true,
									timer: 1500,
								});
							})
							.catch(function (error) {
								console.log(error);
							});
					}}>
					Add
				</Button>
			</Box>
			<Box sx={{ display: "flex", my: 1.5 }}>
				<FormControl size='small'>
					<InputLabel>Select Region</InputLabel>
					<Select
						label='Select Region'
						sx={{ minWidth: "170px" }}
						size='small'
						value={selectRegion?.regionTeam}
						onChange={(e) => setSelectRegion(e.target.value)}>
						{regions?.map((r) => (
							<MenuItem value={r}>{r?.regionTeam}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl size='small'>
					<InputLabel>Select Manager</InputLabel>
					<Select
						label='Select Manager'
						sx={{ minWidth: "170px" }}
						size='small'
						value={selectmanager?.managerTeam}
						onChange={(e) => setSelectManager(e.target.value)}>
						{managers
							?.filter((f) => f?.underRegionTeam === selectRegion?.regionTeam)
							?.map((r) => (
								<MenuItem value={r}>{r?.managerTeam}</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl size='small'>
					<InputLabel>Select Team Lead</InputLabel>
					<Select
						label='Select Team Lead'
						sx={{ minWidth: "170px" }}
						size='small'
						value={selectTeamLead?.teamLead}
						onChange={(e) => setSelectTeamLead(e.target.value)}>
						{teamLeads
							?.filter(
								(f) => f?.underManagerTeam === selectmanager?.managerTeam,
							)
							?.map((r) => (
								<MenuItem value={r}>{r?.teamLead}</MenuItem>
							))}
					</Select>
				</FormControl>
				<TextField
					size='small'
					id='outlined-basic'
					onChange={(e) => setTrainer(e.target.value)}
					label='Trainer Team Name'
				/>
				<Button
					className='sendButton'
					type='submit'
					variant='contained'
					sx={{
						width: "100px",
						pointerEvents:
							selectRegion && selectmanager && selectTeamLead && trainer
								? "auto"
								: "none",
						opacity:
							selectRegion && selectmanager && selectTeamLead && trainer
								? "1"
								: "0.5",
					}}
					onClick={() => {
						setSubmitting(true);
						axios
							.post(`https://americasolarpros.herokuapp.com/addTeamOfTrainers`, {
								underRegionTeam: selectRegion?.regionTeam,
								underManagerTeam: selectmanager?.managerTeam,
								underTeamLeadTeam: selectTeamLead?.teamLead,
								trainer,
								type: "Trainer",
								teamLeaders: [],
								trainerAgents: [],
							})
							.then(function (response) {
								setSubmitting(false);
								Swal.fire({
									icon: "success",
									title: "Successfully Added",
									showConfirmButton: true,
									timer: 1500,
								});
							})
							.catch(function (error) {
								console.log(error);
							});
					}}>
					Add
				</Button>
			</Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Divider sx={{ mt: 1, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				variant='h5'
				sx={{ fontWeight: "bold", mb: 2 }}>
				Regionals
			</Typography>
			<Grid container spacing={2}>
				{regions?.map((r) => (
					<Grid item md={4} xs={6}>
						<RemoveCircleIcon
							className='textColor'
							onClick={() => {
								Swal.fire({
									title: "Are you sure?",
									text: "You won't be able to revert this!",
									icon: "warning",
									showCancelButton: true,
									confirmButtonColor: "#3085d6",
									cancelButtonColor: "#d33",
									confirmButtonText: "Yes, delete it!",
								}).then((result) => {
									if (result.isConfirmed) {
										setSubmitting(true);
										axios
											.delete(
												`https://americasolarpros.herokuapp.com/deleteTeams/${r?._id}`,
											)
											.then(function (response) {
												Swal.fire("Deleted!", "Deleted", "success");
												setSubmitting(false);
											})
											.catch(function (error) {
												console.log(error);
											});
									}
								});
							}}
							sx={{ cursor: "pointer" }}
						/>
						<Button
							onClick={() => setSelectRegionTree(r?.regionTeam)}
							className={
								r?.regionTeam === selectRegionTree && "activeBtnPayment"
							}>
							{r?.regionTeam}
						</Button>
						<CancelIcon
							className='textColor'
							onClick={() => {
								setSelectRegionTree(null);
								setSelectManagerTree(null);
								setSelectTeamLeadTree(null);
								/* setSelectTrainerTree(null); */
							}}
							sx={{ cursor: "pointer" }}
						/>
						<Box sx={{ display: "flex" }}>
							<Autocomplete
								sx={{ my: 1, width: "100%" }}
								size='small'
								onChange={(event, newValue) => {
									setValue(
										newValue?.map((user) => {
											return { url: user?.url };
										}),
									);
								}}
								multiple
								id='tags-outlined'
								defaultValue={r?.teamLeaders?.map((s) => s)}
								options={users || []}
								getOptionLabel={(option) => option?.url}
								filterSelectedOptions
								disableCloseOnSelect
								renderInput={(params) => (
									<TextField
										{...params}
										label={`Select ${r?.regionTeam} Head`}
										placeholder='Select Users'
									/>
								)}
							/>
							<Button
								sx={{ my: 1, width: "100px" }}
								onClick={() => {
									setSubmitting(true);
									axios
										.put(
											`https://americasolarpros.herokuapp.com/addTeamMemberOfRegion/${r?._id}`,
											value,
										)
										.then(function (response) {
											setSubmitting(false);
											Swal.fire({
												icon: "success",
												title: "Successfully Added",
												showConfirmButton: true,
												timer: 1500,
											});
										})
										.catch(function (error) {
											console.log(error);
										});
								}}>
								Add
							</Button>
						</Box>
					</Grid>
				))}
			</Grid>
			{selectRegionTree && (
				<>
					<Divider
						sx={{ mt: 1, border: "1px solid" }}
						className='borderColor'
					/>
					<Typography
						className='textColor'
						variant='h5'
						sx={{ fontWeight: "bold", mb: 2 }}>
						Managers
					</Typography>
				</>
			)}
			<Grid container spacing={2}>
				{managers
					?.filter((f) => f?.underRegionTeam === selectRegionTree)
					.map((m) => (
						<Grid item md={4} xs={6}>
							<RemoveCircleIcon
								className='textColor'
								onClick={() => {
									Swal.fire({
										title: "Are you sure?",
										text: "You won't be able to revert this!",
										icon: "warning",
										showCancelButton: true,
										confirmButtonColor: "#3085d6",
										cancelButtonColor: "#d33",
										confirmButtonText: "Yes, delete it!",
									}).then((result) => {
										if (result.isConfirmed) {
											setSubmitting(true);
											axios
												.delete(
													`https://americasolarpros.herokuapp.com/deleteTeams/${m?._id}`,
												)
												.then(function (response) {
													Swal.fire("Deleted!", "Deleted", "success");
													setSubmitting(false);
												})
												.catch(function (error) {
													console.log(error);
												});
										}
									});
								}}
								sx={{ cursor: "pointer" }}
							/>
							<Button
								onClick={() => setSelectManagerTree(m?.managerTeam)}
								className={
									m?.managerTeam === selectManagerTree && "activeBtnPayment"
								}>
								{m?.managerTeam}
							</Button>
							<CancelIcon
								className='textColor'
								onClick={() => {
									setSelectManagerTree(null);
									setSelectTeamLeadTree(null);
									/* setSelectTrainerTree(null); */
								}}
								sx={{ cursor: "pointer" }}
							/>
							<Box sx={{ display: "flex" }}>
								<Autocomplete
									sx={{ my: 1, width: "100%" }}
									size='small'
									onChange={(event, newValue) => {
										setValue(
											newValue?.map((user) => {
												return { url: user?.url };
											}),
										);
									}}
									multiple
									id='tags-outlined'
									defaultValue={m?.teamLeaders?.map((s) => s)}
									options={users || []}
									getOptionLabel={(option) => option?.url}
									filterSelectedOptions
									disableCloseOnSelect
									renderInput={(params) => (
										<TextField
											{...params}
											label={`Select ${m?.managerTeam} Head`}
											placeholder='Select Users'
										/>
									)}
								/>
								<Button
									sx={{ my: 1, width: "100px" }}
									onClick={() => {
										setSubmitting(true);
										axios
											.put(
												`https://americasolarpros.herokuapp.com/addTeamMemberOfManager/${m?._id}`,
												value,
											)
											.then(function (response) {
												setSubmitting(false);
												Swal.fire({
													icon: "success",
													title: "Successfully Added",
													showConfirmButton: true,
													timer: 1500,
												});
											})
											.catch(function (error) {
												console.log(error);
											});
									}}>
									Update
								</Button>
							</Box>
						</Grid>
					))}
			</Grid>
			{selectManagerTree && (
				<>
					<Divider
						sx={{ mt: 1, border: "1px solid" }}
						className='borderColor'
					/>
					<Typography
						className='textColor'
						variant='h5'
						sx={{ fontWeight: "bold", mb: 2 }}>
						Team Leads
					</Typography>
				</>
			)}
			<Grid container spacing={2}>
				{teamLeads
					?.filter((f) => f?.underManagerTeam === selectManagerTree)
					.map((t) => (
						<Grid item md={4} xs={6}>
							<RemoveCircleIcon
								className='textColor'
								onClick={() => {
									Swal.fire({
										title: "Are you sure?",
										text: "You won't be able to revert this!",
										icon: "warning",
										showCancelButton: true,
										confirmButtonColor: "#3085d6",
										cancelButtonColor: "#d33",
										confirmButtonText: "Yes, delete it!",
									}).then((result) => {
										if (result.isConfirmed) {
											setSubmitting(true);
											axios
												.delete(
													`https://americasolarpros.herokuapp.com/deleteTeams/${t?._id}`,
												)
												.then(function (response) {
													Swal.fire("Deleted!", "Deleted", "success");
													setSubmitting(false);
												})
												.catch(function (error) {
													console.log(error);
												});
										}
									});
								}}
								sx={{ cursor: "pointer" }}
							/>
							<Button
								onClick={() => setSelectTeamLeadTree(t?.teamLead)}
								className={
									t?.teamLead === selectTeamLeadTree && "activeBtnPayment"
								}>
								{t?.teamLead}
							</Button>
							<CancelIcon
								className='textColor'
								onClick={() => {
									setSelectTeamLeadTree(null);
									/* setSelectTrainerTree(null); */
								}}
								sx={{ cursor: "pointer" }}
							/>
							<Box sx={{ display: "flex" }}>
								<Autocomplete
									sx={{ my: 1, width: "100%" }}
									size='small'
									onChange={(event, newValue) => {
										setValue(
											newValue?.map((user) => {
												return { url: user?.url };
											}),
										);
									}}
									multiple
									id='tags-outlined'
									defaultValue={t?.teamLeaders?.map((s) => s)}
									options={users || []}
									getOptionLabel={(option) => option?.url}
									filterSelectedOptions
									disableCloseOnSelect
									renderInput={(params) => (
										<TextField
											{...params}
											label={`Select ${t?.teamLead} Head`}
											placeholder='Select Users'
										/>
									)}
								/>{" "}
								<Button
									sx={{ my: 1, width: "100px" }}
									onClick={() => {
										setSubmitting(true);
										axios
											.put(
												`https://americasolarpros.herokuapp.com/addTeamMemberOfTeamLead/${t?._id}`,
												value,
											)
											.then(function (response) {
												setSubmitting(false);
												Swal.fire({
													icon: "success",
													title: "Successfully Added",
													showConfirmButton: true,
													timer: 1500,
												});
											})
											.catch(function (error) {
												console.log(error);
											});
									}}>
									Update
								</Button>
							</Box>
						</Grid>
					))}
			</Grid>
			{selectTeamLeadTree && (
				<>
					<Divider
						sx={{ mt: 1, border: "1px solid" }}
						className='borderColor'
					/>
					<Typography
						className='textColor'
						variant='h5'
						sx={{ fontWeight: "bold", mb: 2 }}>
						Trainers
					</Typography>
				</>
			)}
			<Grid container spacing={2}>
				{trainers
					?.filter((f) => f?.underTeamLeadTeam === selectTeamLeadTree)
					.map((t) => (
						<Grid item md={4} xs={6}>
							<RemoveCircleIcon
								className='textColor'
								onClick={() => {
									Swal.fire({
										title: "Are you sure?",
										text: "You won't be able to revert this!",
										icon: "warning",
										showCancelButton: true,
										confirmButtonColor: "#3085d6",
										cancelButtonColor: "#d33",
										confirmButtonText: "Yes, delete it!",
									}).then((result) => {
										if (result.isConfirmed) {
											setSubmitting(true);
											axios
												.delete(
													`https://americasolarpros.herokuapp.com/deleteTeams/${t?._id}`,
												)
												.then(function (response) {
													Swal.fire("Deleted!", "Deleted", "success");
													setSubmitting(false);
												})
												.catch(function (error) {
													console.log(error);
												});
										}
									});
								}}
								sx={{ cursor: "pointer" }}
							/>
							<Button /* onClick={() => setSelectTrainerTree(t?.trainer)} */>
								{t?.trainer}
							</Button>
							<Box sx={{ display: "flex" }}>
								<Autocomplete
									sx={{ my: 1, width: "100%" }}
									size='small'
									onChange={(event, newValue) => {
										setValue(
											newValue?.map((user) => {
												return { url: user?.url };
											}),
										);
									}}
									multiple
									id='tags-outlined'
									defaultValue={t?.teamLeaders?.map((s) => s)}
									options={users || []}
									getOptionLabel={(option) => option?.url}
									filterSelectedOptions
									disableCloseOnSelect
									renderInput={(params) => (
										<TextField
											{...params}
											label={`Select ${t?.trainer} Head`}
											placeholder='Select Users'
										/>
									)}
								/>
								<Button
									sx={{ my: 1, width: "100px" }}
									onClick={() => {
										setSubmitting(true);
										axios
											.put(
												`https://americasolarpros.herokuapp.com/addTeamMemberOfTrainer/${t?._id}`,
												value,
											)
											.then(function (response) {
												setSubmitting(false);
												Swal.fire({
													icon: "success",
													title: "Successfully Added",
													showConfirmButton: true,
													timer: 1500,
												});
											})
											.catch(function (error) {
												console.log(error);
											});
									}}>
									Update
								</Button>
							</Box>
							<Box sx={{ display: "flex" }}>
								<Autocomplete
									sx={{ my: 1, width: "100%" }}
									size='small'
									onChange={(event, newValue) => {
										setValue(
											newValue?.map((user) => {
												return { url: user?.url };
											}),
										);
									}}
									multiple
									id='tags-outlined'
									defaultValue={t?.trainerAgents?.map((s) => s)}
									options={users || []}
									getOptionLabel={(option) => option?.url}
									filterSelectedOptions
									disableCloseOnSelect
									renderInput={(params) => (
										<TextField
											{...params}
											label={`Select ${t?.trainer} Agents`}
											placeholder='Select Users'
										/>
									)}
								/>
								<Button
									sx={{ my: 1, width: "100px" }}
									onClick={() => {
										setSubmitting(true);
										axios
											.put(
												`https://americasolarpros.herokuapp.com/addTeamMemberOfTrainerAgents/${t?._id}`,
												value,
											)
											.then(function (response) {
												setSubmitting(false);
												Swal.fire({
													icon: "success",
													title: "Successfully Added",
													showConfirmButton: true,
													timer: 1500,
												});
											})
											.catch(function (error) {
												console.log(error);
											});
									}}>
									Update
								</Button>
							</Box>
						</Grid>
					))}
			</Grid>
		</Box>
	);
};

export default TeamManage;
