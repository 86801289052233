import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box } from "@mui/system";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ScheduleDetails = ({
	selectedDateTime,
	setNextStep,
	selectProduct,
	setSelectedDateTime,
}) => {
	return (
		<>
			<Grid item xs={12} md={5}>
				<ArrowBackIcon
					className=' bgColor'
					sx={{
						mb: 1.5,
						p: 0.5,
						borderRadius: 5,
						color: "white",
						cursor: "pointer",
					}}
					onClick={() => {
						setNextStep(false);
						setSelectedDateTime();
					}}
				/>
				<br />
				<img
					style={{ width: "70px", height: "70px", borderRadius: "50%" }}
					src='/icon.png'
					alt=''
				/>
				<br />
				<Typography variant='button' sx={{ fontWeight: "bold", my: 1 }}>
					America Solar
				</Typography>
				<Typography variant='h5' sx={{ fontWeight: "bold", my: 1 }}>
					1-0n-1 Interview
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
					<AccessTimeIcon sx={{ mr: 1 }} />
					<Typography variant='button' sx={{ fontWeight: "bold" }}>
						Anytime
					</Typography>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
					<CallIcon sx={{ mr: 1 }} />
					<Typography variant='button' sx={{ fontWeight: "bold" }}>
						Phone Call
					</Typography>
				</Box>
				{selectedDateTime && (
					<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
						<EventNoteIcon sx={{ mr: 1 }} />
						<Typography variant='button' sx={{ fontWeight: "bold" }}>
							{selectedDateTime}
						</Typography>
					</Box>
				)}
				<Typography
					variant='body2'
					gutterBottom
					sx={{ my: 2, lineHeight: 1.5 }}>
					Please be ready for the scheduled time. We will contact you by phone
					and answer any questions you have. During the call, we will determine
					if you're the right fit for America Solar
				</Typography>
			</Grid>
		</>
	);
};

export default ScheduleDetails;
