import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAPI } from "../../../../apiContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "30vw", sm: "60vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const Note = ({ datas, openNote, setOpenNote }) => {
	const { databyEmail } = useAPI();
	const form = useRef();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			note: "",
		},
	});
	useEffect(() => {
		axios
			.get(`https://americasolarpros.herokuapp.com/commissionNote/${datas?._id}`)
			.then((res) => {
				reset(res.data?.note);
			});
	}, [datas?._id, reset]);
	const onSubmit = ({ note }) => {
		setSubmitting(true);

		axios
			.put(`https://americasolarpros.herokuapp.com/commissionNote/${datas?._id}`, {
				note,
				submitTime: new Date().toLocaleString("en-US", {
					timeZone: "America/Los_Angeles",
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
					second: "2-digit",
					timeZoneName: "short",
				}),
			})
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<div>
			{databyEmail ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={openNote}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={openNote}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setOpenNote(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Typography
								variant='h4'
								gutterBottom
								className='textColor'
								sx={{ fontWeight: "bold" }}>
								Note
							</Typography>
							<Typography sx={{ pb: 2 }} className='textColor'>
								Last updated in {datas?.note?.submitTime || "N/A"}
							</Typography>

							<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
								<Grid container spacing={3}>
									{databyEmail?.role === "Admin" ? (
										<Grid item md={12} sm={12} xs={12}>
											<TextField
												required
												fullWidth
												multiline
												rows={5}
												label='Note'
												InputLabelProps={{
													shrink: true,
												}}
												{...register("note", { required: true })}
											/>
										</Grid>
									) : (
										<Grid item md={12} sm={12} xs={12}>
											<TextField
												disabled
												required
												fullWidth
												multiline
												rows={5}
												label='Note'
												InputLabelProps={{
													shrink: true,
												}}
												{...register("note", { required: true })}
											/>
										</Grid>
									)}
									{databyEmail?.role === "Admin" && (
										<Grid item md={12} sm={12} xs={12}>
											<Button
												type='submit'
												variant='contained'
												sx={{
													width: "100%",
													mb: 2,
													px: 3,
													fontWeight: "bold",
													borderRadius: "25px",
												}}>
												Update
											</Button>
										</Grid>
									)}
								</Grid>
							</form>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !databyEmail}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default Note;
