import React from "react";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	Container,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

const PublishFundingBoard = () => {
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({ title, video, description }) => {
		const data = {
			title,
			video,
			description,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		setSubmitting(true);
		axios
			.post(`https://americasolarpros.herokuapp.com/FundingBoard`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Posted",
					showConfirmButton: true,
					timer: 950000,
				}).then(function () {
					reset();
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 2 }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'
				gutterBottom>
				Post Funding Board
			</Typography>
			<Grid container spacing={2}>
				<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
					<form data-aos='fade-left' onSubmit={handleSubmit(onSubmit)}>
						<TextField
							required
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Title'
							{...register("title", { required: true })}
						/>
						<TextField
							required
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Description'
							multiline
							rows={5}
							{...register("description", { required: true })}
						/>
						<TextField
							required
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Video Link'
							{...register("video", { required: true })}
						/>
						<Button
							type='submit'
							variant='contained'
							className='button border'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
							}}>
							Post Training
						</Button>
					</form>
				</Grid>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default PublishFundingBoard;
