import {
	CircularProgress,
	Backdrop,
	Button,
	Grid,
	InputLabel,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { useAPI } from "../../apiContext";

const ScheduleQuestions = ({ selectedDateTime }) => {
	const { data, databyEmail } = useAPI();
	const url = window.location.pathname;
	const [selectuser, setSelectuser] = useState();
	useEffect(() => {
		if (url.includes("/dashboard")) {
			setSelectuser(databyEmail);
		} else {
			setSelectuser(data);
		}
	}, [data, databyEmail, url]);
	const form = useRef();
	const [submitting, setSubmitting] = useState(false);

	const { register, handleSubmit, reset } = useForm();
	const onSubmit = (data) => {
		const datas = {
			...data,
			invitationCode: selectuser?.url,
			selectedDateTime,
			assignedTo: "None",
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
			status: "Pending",
			scheduleId: `Meeting${Math.floor(100000 + Math.random() * 900000)}`,
			invitedRecrutedBy: selectuser?.recuretedBy,
		};
		setSubmitting(true);
		axios
			.post(`https://americasolarpros.herokuapp.com/meetings`, datas)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your meeting has been booked Successfully",
					showConfirmButton: true,
				}).then(function () {
					reset();
					window.location.href = `/@${selectuser?.url}`;
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<>
			<Grid item xs={12} md={5.5}>
				<Typography
					className='textColor'
					variant='h6'
					sx={{ fontWeight: "bold", mb: 2 }}>
					Enter Details
				</Typography>
				<form ref={form} data-aos='fade-left' onSubmit={handleSubmit(onSubmit)}>
					<InputLabel htmlFor='input-with-icon-adornment'>Name</InputLabel>
					<TextField
						required
						sx={{ width: "100%", mb: 2 }}
						id='"outlined-multiline-flexible'
						{...register("name", { required: true })}
					/>
					<InputLabel htmlFor='input-with-icon-adornment'>Email</InputLabel>
					<TextField
						required
						sx={{ width: "100%", mb: 2 }}
						id='"outlined-multiline-flexible'
						type='email'
						{...register("email", { required: true })}
					/>
					<InputLabel htmlFor='input-with-icon-adornment'>
						Phone Number
					</InputLabel>
					<TextField
						required
						type='tel'
						sx={{ width: "100%", mb: 2 }}
						id='"outlined-multiline-flexible'
						{...register("phoneNumber", { required: true })}
					/>
					<InputLabel htmlFor='input-with-icon-adornment'>
						Tell Us Something You Need To Know
					</InputLabel>
					<TextField
						required
						multiline
						rows={4}
						sx={{ width: "100%", mb: 2 }}
						id='"outlined-multiline-flexible'
						{...register("details", { required: true })}
					/>
					<Button
						type='submit'
						className='confirm'
						sx={{ width: "180px", p: 2 }}>
						Schedule Meeting
					</Button>
				</form>
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={submitting}>
					<CircularProgress color='inherit' />
				</Backdrop>
			</Grid>
		</>
	);
};

export default ScheduleQuestions;
