import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { CircularProgress, Backdrop, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import Cashapp from "../../AddPaymentDetails/Cashapp";
import WireTransfer from "../../AddPaymentDetails/WireTransfer";
import Paypal from "../../AddPaymentDetails/Paypal";
import Venmo from "../../AddPaymentDetails/Venmo";
import W3Form from "../../AddPaymentDetails/W3Form";
import Switch from "react-switch";
import { useAPI } from "../../../../apiContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "70vw", sm: "80vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const Payment = ({ datas, pay, setPay }) => {
	const { databyEmail } = useAPI();
	const [payment, setPayment] = useState();
	React.useEffect(() => {
		axios
			.get(`https://americasolarpros.herokuapp.com/paymentInfo/${datas}`)
			.then((res) => {
				setPayment(res.data);
			});
	}, [datas]);
	const [w9form, setW9form] = useState(false);
	const handleChange = (nextChecked) => {
		setW9form(nextChecked);
	};
	return (
		<div>
			{databyEmail ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={pay}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={pay}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<Box
								sx={{
									position: "fixed",
									top: "5px",
									left: "5px",
									display: "flex",
								}}>
								<Switch
									onChange={handleChange}
									checked={w9form}
									className='react-switch'
								/>
								<Typography
									className='textColor'
									variant='h5'
									sx={{ fontWeight: "bold", pl: 0.5 }}>
									W-9
								</Typography>
							</Box>

							<CancelIcon
								onClick={() => setPay(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Box sx={{ py: 2.3 }}>
								{!w9form ? (
									<>
										{payment?.defaultMethod === "Paypal" && (
											<Paypal data={payment} />
										)}
										{payment?.defaultMethod === "Venmo" && (
											<Venmo data={payment} />
										)}
										{payment?.defaultMethod === "CashApp" && (
											<Cashapp data={payment} />
										)}
										{payment?.defaultMethod === "WireTransfer" && (
											<WireTransfer data={payment} />
										)}
										{payment?.defaultMethod === "" && (
											<Typography
												className='textColor'
												variant='h5'
												sx={{ fontWeight: "bold", pl: 0.5 }}>
												Haven't selected any payment method yet
											</Typography>
										)}
									</>
								) : (
									<W3Form data={payment} />
								)}
							</Box>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!databyEmail || !payment}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default Payment;
