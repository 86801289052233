import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SingleUser from "./SingleUser";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Note from "./Note";
import { useAPI } from "../../../../apiContext";
import ReSchedule from "./ReSchedule";
import { DataGrid } from "@mui/x-data-grid";
import DeleteFunction from "./DeleteFunction";
import StatusChange from "./StatusChange";
import AssignedChange from "./AssignedChange";
import Search from "../../../../Pagination/Search";
import Pagination from "../../../../Pagination/Pagination";

const DesignStarted = () => {
	const { databyEmail, SetMenuItems } = useAPI();
	const [open, setOpen] = useState(false);
	const [dateOpen, setDateOpen] = useState(false);
	const [openNote, setOpenNote] = useState(false);
	const [user, setUser] = useState();
	function setTime(time) {
		const previousZone = new Date(time?.split("-")[0]).toLocaleString("en-US", {
			timeZone: time?.split("-")[1],
		});
		const localZone = new Date(previousZone)?.toLocaleString("en-US", {
			timeZone: Intl.DateTimeFormat().resolvedOptions()?.timeZone,
		});
		return localZone;
	}
	const [submitting, setSubmitting] = useState(false);
	const [allUsers, setAllUsers] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	// Function to handle page change
	const handlePageChange = (newPage, newItemsPerPage) => {
		setCurrentPage(newPage);
		if (newItemsPerPage !== undefined) {
			setItemsPerPage(newItemsPerPage);
		}
	};
	useEffect(() => {
		fetch(
			`https://americasolarpros.herokuapp.com/invitatedUsersByStatus/Design-started?page=${currentPage}&perPage=${itemsPerPage}${searchQuery}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setAllUsers(data.data);
				setTotalItems(data?.pagination?.total);
			});
	}, [currentPage, itemsPerPage, searchQuery, submitting]);

	const [filter, setFilter] = useState();
	useEffect(() => {
		setFilter(
			allUsers?.filter(
				(p) =>
					p?.invitationCode === databyEmail?.url ||
					(Array.isArray(p?.assignedTo) &&
						p.assignedTo.some(
							(assignee) =>
								typeof assignee === "object" &&
								assignee?.email === databyEmail?.email,
						)),
			) ?? [],
		);
	}, [allUsers, databyEmail?.email, databyEmail?.url]);

	const [filtered, setFiltered] = useState();
	useEffect(() => {
		setFiltered(
			databyEmail?.role === ("Admin" || "Manager") ? allUsers : filter,
		);
	}, [allUsers, filter, databyEmail?.role]);
	const getAssigned = (assignedTo) => {
		const assigneeList = Array.isArray(assignedTo)
			? assignedTo.map((assignee) => (
				<div key={assignee?.email}>({assignee?.url})&nbsp;</div>
			))
			: null;

		return assigneeList;
	};
	const [assingChange, setAssingChange] = useState(false);

	const columns = [
		{
			field: "scheduleId",
			headerName: "ID",
			minWidth: 150,
		},
		{
			field: "scheduleType",
			headerName: "Type",
			minWidth: 100,
		},
		{
			field: "submitTime",
			headerName: "Submited On",
			minWidth: 220,
		},
		{
			field: "userName",
			headerName: "Scheduled For",
			minWidth: 200,
			valueGetter: (params) => params?.row?.selectedDateTime,
			renderCell: (params) => {
				return (
					<span
						style={{ cursor: "pointer", color: "red" }}
						onClick={() => {
							setDateOpen(true);
							setUser(params?.row);
						}}>
						{setTime(params?.row?.selectedDateTime)}
					</span>
				);
			},
		},
		{
			field: "ProductName",
			headerName: "Product",
			minWidth: 300,
			valueGetter: (params) => params?.row?.selectedProduct?.name,
			renderCell: (params) => {
				return params?.row?.selectedProduct?.name;
			},
		},
		{
			field: "assignedToDetail",
			headerName: "Asigned To",
			minWidth: 300,
			valueGetter: (params) => params?.row?.assignedTo,
			renderCell: (params) => {
				return (
					<>
						{databyEmail && databyEmail?.role !== "Agent" ? (
							<Button
								variant='contained'
								color='primary'
								onClick={() =>
									setAssingChange({
										user: params?.row,
										setSubmitting,
									})
								}>
								{getAssigned(params?.row?.assignedTo)?.length > 0
									? getAssigned(params?.row?.assignedTo)
									: "N/A"}
							</Button>
						) : (
							<>
								{getAssigned(params?.row?.assignedTo)?.length > 0
									? getAssigned(params?.row?.assignedTo)
									: "N/A"}
							</>
						)}
					</>
				);
			},
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: 150,
		},
		{
			field: "phoneNumber",
			headerName: "Phone",
			minWidth: 120,
			valueGetter: (params) => params?.row?.phoneNumber,
			renderCell: (params) => {
				return (
					<a
						href={`tel:${params?.row?.phoneNumber}`}
						className='textColor'
						style={{ textDecoration: "none" }}>
						{params?.row?.phoneNumber}
					</a>
				);
			},
		},
		{
			field: "invitationCode",
			headerName: "Invited By",
			minWidth: 130,
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: 230,
			valueGetter: (params) => params?.row?.status,
			renderCell: (params) => {
				return params?.row && params?.row?.status === "Closed" ? (
					<Typography variant='p'>
						{SetMenuItems(params?.row?.status) ?? "N/A"}
					</Typography>
				) : (
					<>
						{(databyEmail && databyEmail?.role !== "Agent") ||
							params?.row?.invitationCode === databyEmail?.url ? (
							<StatusChange
								user={params?.row}
								setSubmitting={setSubmitting}
								databyEmail={databyEmail}
								SetMenuItems={SetMenuItems}
							/>
						) : (
							<Typography variant='p'>
								{SetMenuItems(params?.row?.status) ?? "N/A"}
							</Typography>
						)}
					</>
				);
			},
		},
		{
			field: "view",
			headerName: "View",
			minWidth: 240,
			filterable: false,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Button
							onClick={() => {
								setOpen(true);
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<RemoveRedEyeIcon />
						</Button>
						<Button
							onClick={() => {
								setOpenNote(true);
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<NoteAddIcon />
						</Button>
						<DeleteFunction
							setSubmitting={setSubmitting}
							databyEmail={databyEmail}
							params={params}
						/>
					</Box>
				);
			},
		},
	];

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				Design Started Opportunities
			</Typography>
			{filtered && (
				<Box sx={{ width: "100%", height: "80vh" }}>
					<DataGrid
						rows={filtered}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: () => (
								<Search
									setSearchQuery={setSearchQuery}
									searchFields={[
										"name",
										"phoneNumber",
										"email",
										"scheduleId",
										"scheduleType",
										"selectedDateTime",
										"status",
										"invitationCode",
										"submitTime",
										"guestEmails",
										"dealClosed",
									]}
								/>
							),
							Footer: () => (
								<Pagination
									totalItems={totalItems}
									currentPage={currentPage}
									itemsPerPage={itemsPerPage}
									onPageChange={handlePageChange}
								/>
							),
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!allUsers}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && <SingleUser open={open} setOpen={setOpen} user={user} />}
			{openNote && (
				<Note openNote={openNote} setOpenNote={setOpenNote} user={user} />
			)}
			{dateOpen && (
				<ReSchedule dateOpen={dateOpen} setDateOpen={setDateOpen} user={user} />
			)}
			{assingChange && (
				<AssignedChange
					assingChange={assingChange}
					setAssingChange={setAssingChange}
				/>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!allUsers}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default DesignStarted;
