import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Backdrop, CircularProgress } from "@mui/material";
import SubmitInfo from "./SubmitInfo";
import { useState } from "react";
import { useEffect } from "react";
import GetAuth from "../../GetAuth";

const TeamInvite = () => {
	const [user, loading] = GetAuth();
	const [users, setUsers] = useState();
	const [state, setState] = useState(false);
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/userbyemail/${user?.email}`)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data);
			});
	}, [state, user?.email]);

	return (
		<Container>
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				minHeight='100vh'>
				<Card
					sx={{
						p: 0.5,
						m: { sm: 0, md: 5 },
						borderRadius: 5,
					}}>
					<CardContent>
						<Grid container spacing={2}>
							{!users ? (
								<Grid item xs={12}>
									<SubmitInfo setState={setState} />
								</Grid>
							) : (
								window.location.replace("/dashboard")
							)}
						</Grid>
					</CardContent>
				</Card>
			</Box>

			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default TeamInvite;
