import React from "react";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	Container,
	Box,
	IconButton,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import styled from "styled-components";

const SocialMediaPost = () => {
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	const [imageLink, setImageLink] = useState(null);
	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "SocialMediaPosts");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	const onSubmit = ({ title, video, description }) => {
		const data = {
			title,
			video,
			image: imageLink,
			description,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		setSubmitting(true);
		axios
			.post(`https://americasolarpros.herokuapp.com/post`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Posted",
					showConfirmButton: true,
					timer: 950000,
				}).then(function () {
					reset();
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 2 }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'
				gutterBottom>
				Add social media post
			</Typography>
			<Grid container spacing={2}>
				<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
					<form data-aos='fade-left' onSubmit={handleSubmit(onSubmit)}>
						<Box
							display='flex'
							flexDirection='column'
							alignItems='center'
							sx={{ mx: "auto", mb: 2 }}>
							<label
								className='bgColor'
								htmlFor='icon-button-file'
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									margin: "0 9px",
									borderRadius: 5,
									cursor: "pointer",
									minWidth: "280px",
								}}>
								<Input
									accept='image/*'
									id='icon-button-file'
									type='file'
									onChange={uploadImage}
								/>
								<Typography
									sx={{ my: 2, ml: 2, color: "white" }}
									variant='button'
									component='div'
									gutterBottom>
									Upload Photo
								</Typography>
								<IconButton
									sx={{ mx: 2 }}
									color='primary'
									aria-label='upload picture'
									component='span'>
									<AddPhotoAlternateIcon
										fontSize='large'
										sx={{ fontWeight: "bold" }}
									/>
								</IconButton>
							</label>

							{loading ? (
								<Box sx={{ my: 2 }}>
									<CircularProgress className='textColor' />
								</Box>
							) : (
								<img src={imageLink} style={{ width: "200px" }} alt='' />
							)}
						</Box>
						<TextField
							required
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Title'
							{...register("title", { required: true })}
						/>
						<TextField
							required
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Description'
							multiline
							rows={5}
							{...register("description", { required: true })}
						/>
						<TextField
							required
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Video Link'
							{...register("video", { required: true })}
						/>
						<Button
							type='submit'
							variant='contained'
							className='button border'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
							}}>
							Post
						</Button>
					</form>
				</Grid>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default SocialMediaPost;
