import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Review from "./Review";
import GetAuth from "../../../GetAuth";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const AllReviews = () => {
	const [open, setOpen] = React.useState(false);
	const [viewId, setViewId] = useState();
	const handleOpen = (reviewViewId) => {
		setViewId(reviewViewId);
		setOpen(true);
	};
	const [id, setId] = useState();
	const [reviews, setReviews] = useState();
	const [deleted, setDeleted] = useState(false);
	const [user] = GetAuth();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/userbyemail/${user?.email}`)
			.then((res) => res.json())
			.then((data) => {
				setId(data?._id);
				setReviews(data?.reviews?.reverse());
			});
	}, [deleted, user?.email]);

	const handleDelete = (deletedReviewId) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setDeleted(true);
				axios
					.put(
						`https://americasolarpros.herokuapp.com/deleteReview/${id}/${deletedReviewId}`,
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That Review has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const columns = [
		{
			field: "Photo",
			headerName: "Photo",
			minWidth: 150,
			renderCell: (params) => {
				return (
					<img
						src={params?.row?.userPhoto || "/user.jpg"}
						alt=''
						width='50px'
						height='50px'
						style={{ borderRadius: "50%" }}
					/>
				);
			},
		},

		{
			field: "userName",
			headerName: "Name",
			flex: 1,
		},
		{
			field: "rating",
			headerName: "Star",
			flex: 1,
		},
		{
			field: "submitTime",
			headerName: "Rec Time",
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 170,
			filterable: false,
			renderCell: (params) => {
				return (
					<>
						<Button
							onClick={() => handleOpen(params?.row?.reviewId)}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<RemoveRedEyeIcon />
						</Button>
						<Button
							className='button border'
							onClick={() => handleDelete(params?.row?.reviewId)}
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
							}}
							variant='contained'>
							<DeleteIcon />
						</Button>
					</>
				);
			},
		},
	];

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				All Reviews
			</Typography>
			{reviews && (
				<Box sx={{ width: "100%", height: "75vh" }}>
					<DataGrid
						rows={reviews}
						getRowId={(row) => row?.reviewId}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}
			{/* <Table size='small' stickyHeader aria-label='sticky table'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='center' colSpan={5}>
								<Typography className='textColor'>
									Last changed in {reviews?.[0]?.submitTime || "N/A"}
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align='left'>No</TableCell>
							<TableCell align='left'>Photo</TableCell>
							<TableCell align='left'>Name</TableCell>
							<TableCell align='left'>Rec Time</TableCell>
							<TableCell align='center'>Action</TableCell>
						</TableRow>
					</TableHead>
					{reviews?.length > 0 ? (
						<TableBody sx={{ td: { py: 1 } }}>
							{reviews?.map((review, key) => (
								<TableRow
									key={key}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}>
									<TableCell align='left'>{count++}</TableCell>
									<TableCell>
										<img
											src={review?.userPhoto || "N/A"}
											alt=''
											width='50px'
											height='50px'
											style={{ borderRadius: "50%" }}
										/>
									</TableCell>
									<TableCell align='left'>
										{review?.userName || "N/A"}
									</TableCell>
									<TableCell align='left'>
										{review?.submitTime || "N/A"}
									</TableCell>
									<TableCell align='left'>{review?.rating}</TableCell>
											 <TableCell align='left'>
												{review?.userReview || "N/A"}
											</TableCell> 
									<TableCell align='center'>
										<Button
											onClick={() => handleOpen(review?.reviewId)}
											className='button border'
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
												m: 0.5,
											}}
											variant='contained'>
											<RemoveRedEyeIcon />
										</Button>
										<Button
											className='button border'
											onClick={() => handleDelete(review?.reviewId)}
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
											}}
											variant='contained'>
											<DeleteIcon />
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
										 <TableCell align='left'>N/A</TableCell> 
								<TableCell align='left'>N/A</TableCell>
							</TableRow>
						</TableHead>
					)}
				</Table> */}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!reviews}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && <Review open={open} setOpen={setOpen} viewId={viewId} id={id} />}
		</Box>
	);
};

export default AllReviews;
