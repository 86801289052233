import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Backdrop, Button, CircularProgress, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import GetAuth from "../../../GetAuth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/system";

const ButtonsLink = () => {
	const [id, setId] = useState();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			email: "",
			facebook: "",
			whatsapp: "",
			youtube: "",
			sms: "",
			call: "",
			twitter: "",
			instagram: "",
			map: "",
			vcf: "",
			pinterest: "",
			alignable: "",
			nextdoor: "",
			linkedin: "",
			zoom: "",
			qrCode: "",
			emailHome: "",
			facebookHome: "",
			whatsappHome: "",
			youtubeHome: "",
			smsHome: "",
			callHome: "",
			twitterHome: "",
			instagramHome: "",
			pinterestHome: "",
			alignableHome: "",
			nextdoorHome: "",
			linkedinHome: "",
			zoomHome: "",
			emailDashB: "",
			facebookDashB: "",
			youtubeDashB: "",
			whatsappDashB: "",
			smsDashB: "",
			callDashB: "",
			twitterDashB: "",
			instagramDashB: "",
			pinterestDashB: "",
			alignableDashB: "",
			nextdoorDashB: "",
			linkedinDashB: "",
			zoomDashB: "",
		},
	});
	const [info, setInfo] = useState();

	const [state, setState] = React.useState({
		emailHome: info?.emailHome,
		facebookHome: info?.facebookHome,
		whatsappHome: info?.whatsappHome,
		youtubeHome: info?.youtubeHome,
		smsHome: info?.smsHome,
		callHome: info?.callHome,
		twitterHome: info?.twitterHome,
		instagramHome: info?.instagramHome,
		pinterestHome: info?.pinterestHome,
		alignableHome: info?.alignableHome,
		nextdoorHome: info?.nextdoorHome,
		linkedinHome: info?.linkedinHome,
		zoomHome: info?.zoomHome,
		emailDashB: info?.emailDashB,
		facebookDashB: info?.facebookDashB,
		whatsappDashB: info?.whatsappDashB,
		youtubeDashB: info?.youtubeDashB,
		smsDashB: info?.smsDashB,
		callDashB: info?.callDashB,
		twitterDashB: info?.twitterDashB,
		instagramDashB: info?.instagramDashB,
		pinterestDashB: info?.pinterestDashB,
		alignableDashB: info?.alignableDashB,
		nextdoorDashB: info?.nextdoorDashB,
		linkedinDashB: info?.linkedinDashB,
		zoomDashB: info?.zoomDashB,
	});

	const {
		emailHome,
		facebookHome,
		whatsappHome,
		youtubeHome,
		smsHome,
		callHome,
		twitterHome,
		instagramHome,
		pinterestHome,
		alignableHome,
		nextdoorHome,
		linkedinHome,
		zoomHome,
		emailDashB,
		facebookDashB,
		youtubeDashB,
		whatsappDashB,
		smsDashB,
		callDashB,
		twitterDashB,
		instagramDashB,
		pinterestDashB,
		alignableDashB,
		nextdoorDashB,
		linkedinDashB,
		zoomDashB,
	} = state;
	const handleChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.checked,
		});
	};
	const [user] = GetAuth();
	useEffect(() => {
		axios
			.get(`https://americasolarpros.herokuapp.com/userbyemail/${user?.email}`)
			.then((res) => {
				reset(res.data?.links);
				setId(res.data?._id);
				setInfo(res.data?.links);
			});
	}, [reset, user?.email]);
	const onSubmit = ({
		email,
		facebook,
		whatsapp,
		youtube,
		sms,
		call,
		twitter,
		instagram,
		qrCode,
		pinterest,
		alignable,
		nextdoor,
		zoom,
		linkedin,
		emailHome,
		facebookHome,
		whatsappHome,
		youtubeHome,
		smsHome,
		callHome,
		twitterHome,
		instagramHome,
		pinterestHome,
		alignableHome,
		nextdoorHome,
		linkedinHome,
		zoomHome,
		emailDashB,
		facebookDashB,
		youtubeDashB,
		whatsappDashB,
		smsDashB,
		callDashB,
		twitterDashB,
		instagramDashB,
		pinterestDashB,
		alignableDashB,
		nextdoorDashB,
		linkedinDashB,
		zoomDashB,
	}) => {
		setSubmitting(true);
		const data = {
			type: "links",
			email,
			facebook,
			whatsapp,
			youtube,
			sms,
			call,
			twitter,
			instagram,
			qrCode,
			pinterest,
			alignable,
			nextdoor,
			linkedin,
			zoom,
			emailHome,
			facebookHome,
			whatsappHome,
			youtubeHome,
			smsHome,
			callHome,
			twitterHome,
			instagramHome,
			pinterestHome,
			alignableHome,
			nextdoorHome,
			linkedinHome,
			zoomHome,
			emailDashB,
			facebookDashB,
			youtubeDashB,
			whatsappDashB,
			smsDashB,
			callDashB,
			twitterDashB,
			instagramDashB,
			pinterestDashB,
			alignableDashB,
			nextdoorDashB,
			linkedinDashB,
			zoomDashB,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		axios
			.put(`https://americasolarpros.herokuapp.com/links/${id}`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Social Media profiles
			</Typography>
			<Typography sx={{ pb: 2 }} className='textColor'>
				Last changed in {info?.submitTime || "N/A"}
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={facebookHome}
								onChange={handleChange}
								name='facebookHome'
								{...register("facebookHome")}
							/>
							<input
								type='checkbox'
								checked={facebookDashB}
								onChange={handleChange}
								name='facebookDashB'
								{...register("facebookDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='Facebook'
							label='Facebook'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("facebook")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={emailHome}
								onChange={handleChange}
								{...register("emailHome")}
								name='emailHome'
							/>
							<input
								type='checkbox'
								checked={emailDashB}
								onChange={handleChange}
								name='emailDashB'
								{...register("emailDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='Email'
							label='Email'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("email")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={whatsappHome}
								onChange={handleChange}
								name='whatsappHome'
								{...register("whatsappHome")}
							/>
							<input
								type='checkbox'
								checked={whatsappDashB}
								onChange={handleChange}
								name='whatsappDashB'
								{...register("whatsappDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='WhatsApp'
							label='WhatsApp'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("whatsapp")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={youtubeHome}
								onChange={handleChange}
								name='youtubeHome'
								{...register("youtubeHome")}
							/>
							<input
								type='checkbox'
								checked={youtubeDashB}
								onChange={handleChange}
								name='youtubeDashB'
								{...register("youtubeDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='Youtube'
							label='Youtube'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("youtube")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={callHome}
								onChange={handleChange}
								name='callHome'
								{...register("callHome")}
							/>
							<input
								type='checkbox'
								checked={callDashB}
								onChange={handleChange}
								name='callDashB'
								{...register("callDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='CAll'
							label='CALL'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("call")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={smsHome}
								onChange={handleChange}
								name='smsHome'
								{...register("smsHome")}
							/>
							<input
								type='checkbox'
								checked={smsDashB}
								onChange={handleChange}
								name='smsDashB'
								{...register("smsDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='SMS'
							label='SMS'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("sms")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={twitterHome}
								onChange={handleChange}
								name='twitterHome'
								{...register("twitterHome")}
							/>
							<input
								type='checkbox'
								checked={twitterDashB}
								onChange={handleChange}
								name='twitterDashB'
								{...register("twitterDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='Twitter'
							label='Twitter'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("twitter")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={instagramHome}
								onChange={handleChange}
								name='instagramHome'
								{...register("instagramHome")}
							/>
							<input
								type='checkbox'
								checked={instagramDashB}
								onChange={handleChange}
								name='instagramDashB'
								{...register("instagramDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='Instagram'
							label='Instagram'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("instagram")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={alignableHome}
								onChange={handleChange}
								name='alignableHome'
								{...register("alignableHome")}
							/>
							<input
								type='checkbox'
								checked={alignableDashB}
								onChange={handleChange}
								name='alignableDashB'
								{...register("alignableDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='alignable'
							label='Alignable'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("alignable")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={nextdoorHome}
								onChange={handleChange}
								name='nextdoorHome'
								{...register("nextdoorHome")}
							/>
							<input
								type='checkbox'
								checked={nextdoorDashB}
								onChange={handleChange}
								name='nextdoorDashB'
								{...register("nextdoorDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='nextdoor'
							label='Nextdoor'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("nextdoor")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={pinterestHome}
								onChange={handleChange}
								name='pinterestHome'
								{...register("pinterestHome")}
							/>
							<input
								type='checkbox'
								checked={pinterestDashB}
								onChange={handleChange}
								name='pinterestDashB'
								{...register("pinterestDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='pinterest'
							label='Pinterest'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("pinterest")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={linkedinHome}
								onChange={handleChange}
								name='linkedinHome'
								{...register("linkedinHome")}
							/>
							<input
								type='checkbox'
								checked={linkedinDashB}
								onChange={handleChange}
								name='linkedinDashB'
								{...register("linkedinDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='linkedin'
							label='LinkedIn'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("linkedin")}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							Card <ArrowForwardIcon fontSize='small' />
							<input
								type='checkbox'
								checked={zoomHome}
								onChange={handleChange}
								name='zoomHome'
								{...register("zoomHome")}
							/>
							<input
								type='checkbox'
								checked={zoomDashB}
								onChange={handleChange}
								name='zoomDashB'
								{...register("zoomDashB")}
							/>
							<ArrowBackIcon fontSize='small' />
							Dashboard
						</Box>
						<TextField
							name='zoom'
							label='Zoom'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("zoom")}
						/>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !info}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ButtonsLink;
