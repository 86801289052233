import React, { useState } from "react";

function Search({ setSearchQuery, searchFields }) {
	const [searchBy, setSearchBy] = useState("");
	const [search, setSearch] = useState("");

	const handleSearch = () => {
		setSearchQuery(`&searchBy=${searchBy}&search=${search}`);
	};

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "flex-start",
				alignItems: "center",
			}}>
			<select
				style={{
					marginRight: "5px",
					padding: "9px",
					borderRadius: "5px",
					border: "1px solid  var(--color1st)",
				}}
				value={searchBy}
				onChange={(e) => setSearchBy(e.target.value)}>
				<option value=''>All</option>
				{searchFields.map((field, index) => (
					<option key={index} value={field}>
						{field}
					</option>
				))}
			</select>
			<input
				style={{
					padding: "9px",
					borderRadius: "5px",
					border: "1px solid  var(--color1st)",
				}}
				type='text'
				onChange={(e) => setSearch(e.target.value)}
				value={search}
				placeholder='Search...'
			/>
			<button
				style={{
					padding: "9px",
					borderRadius: "5px",
					border: "1px solid",
					marginLeft: "5px",
					background: "var(--color1st)",
					color: "white",
				}}
				onClick={handleSearch}>
				Search
			</button>
			<button
				style={{
					padding: "9px",
					borderRadius: "5px",
					border: "1px solid",
					marginLeft: "5px",
					background: "var(--color1st)",
					color: "white",
				}}
				onClick={() => {
					setSearchBy("");
					setSearch("");
					setSearchQuery("");
				}}>
				Clear
			</button>
		</div>
	);
}

export default Search;
