import React, { useState } from "react";
import { RWebShare } from "react-web-share";
import ShareIcon from "@mui/icons-material/Share";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import { useAPI } from "../../../../apiContext";

const ShareButton = () => {
	const { data } = useAPI();
	const [show, setShow] = useState(true);
	const url = window.location.pathname;
	useEffect(() => {
		if (
			url.includes(
				"/dashboard" ||
					"/schedule" ||
					"/jointeam" ||
					"/login" ||
					"/signup" ||
					"/forgotpassword" ||
					"/resetpassword" ||
					"/emailVerification" ||
					"/faq",
			)
		) {
			setShow(false);
		}
	}, [url]);
	return (
		<div>
			{show && (
				<RWebShare
					data={{
						text: `${data?.headline?.title}`,
						url: `${window.location.origin.toString()}/@${data?.url}`,
						title: `${data?.headline?.title}`,
					}}
					onClick={() => console.log("shared successfully!")}>
					<IconButton
						disableFocusRipple={true}
						className='share'
						style={{ position: "fixed", bottom: "9px", right: "9px" }}>
						<ShareIcon className='icon' sx={{ fontSize: 35 }} />
					</IconButton>
				</RWebShare>
			)}
		</div>
	);
};

export default ShareButton;
