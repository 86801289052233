import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAPI } from "../../../../apiContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "30vw", sm: "60vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const EditProfile = ({ user, open, setOpen }) => {
	const { databyEmail } = useAPI();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			addressLine1: "",
			state: "",
			city: "",
			zip: "",
			country: "",
		},
	});
	const [updatetime, setupdatetime] = useState();
	useEffect(() => {
		axios
			.get(`https://americasolarpros.herokuapp.com/userbyemail/${user?.email}`)
			.then((res) => {
				reset(res.data?.address);
				setupdatetime(res.data?.address.submitTime);
			});
	}, [user, reset]);
	const onSubmit = ({ phone, addressLine1, state, city, zip, country }) => {
		setSubmitting(true);
		const address = {
			addressLine1,
			state,
			city,
			zip,
			country,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};

		axios
			.put(`https://americasolarpros.herokuapp.com/profileEdit/${user?._id}`, {
				address,
				phone,
			})
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<div>
			{updatetime ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setOpen(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Typography
								variant='h4'
								gutterBottom
								className='textColor'
								sx={{ fontWeight: "bold" }}>
								Update Profile
							</Typography>
							<Typography sx={{ pb: 2 }} className='textColor'>
								Last updated in {updatetime || "N/A"}
							</Typography>

							<form
								onSubmit={handleSubmit(onSubmit)}
								method='post'
								style={{ display: "flex", flexDirection: "column" }}>
								<Grid container>
									<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
										<TextField
											disabled
											InputLabelProps={{
												shrink: true,
											}}
											fullWidth
											size='small'
											id='email'
											label='Email Address'
											name='email'
											autoComplete='email'
											autoFocus
											value={user?.email}
											sx={{ maxWidth: "100%" }}
										/>
									</Grid>
									<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
										<TextField
											required
											InputLabelProps={{
												shrink: true,
											}}
											fullWidth
											size='small'
											id='phone'
											label='Your Phone Number'
											name='phone'
											autoComplete='phone'
											autoFocus
											sx={{ maxWidth: "100%" }}
											defaultValue={user?.phone}
											{...register("phone", { required: true })}
										/>
									</Grid>
									<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
										<TextField
											required
											InputLabelProps={{
												shrink: true,
											}}
											fullWidth
											size='small'
											sx={{ width: "100%" }}
											id='outlined-basic'
											type={"text"}
											name='Address Line 1'
											label='Address Line 1'
											{...register("addressLine1", { required: true })}
										/>
									</Grid>

									<Grid item md={12} xs={12} sx={{ mx: "auto", my: 0.7 }}>
										<Grid container spacing={1.5}>
											<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
												<TextField
													sx={{ width: "100%" }}
													fullWidth
													InputLabelProps={{
														shrink: true,
													}}
													size='small'
													id='outlined-basic'
													type={"text"}
													name='City'
													label='City'
													{...register("city", { required: true })}
												/>
											</Grid>
											<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
												<TextField
													required
													InputLabelProps={{
														shrink: true,
													}}
													fullWidth
													size='small'
													sx={{ width: "100%" }}
													id='outlined-basic'
													type={"text"}
													name='State/Region'
													label='State/Region'
													{...register("state", { required: true })}
												/>
											</Grid>
											<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
												<TextField
													InputLabelProps={{
														shrink: true,
													}}
													required
													fullWidth
													size='small'
													sx={{ width: "100%" }}
													id='outlined-basic'
													type={"text"}
													name='Zip/Postal Code'
													label='Zip/Postal Code'
													{...register("zip", { required: true })}
												/>
											</Grid>
											<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
												<TextField
													InputLabelProps={{
														shrink: true,
													}}
													required
													fullWidth
													size='small'
													sx={{ width: "100%" }}
													id='outlined-basic'
													type={"text"}
													name='Country'
													label='Country'
													{...register("country", { required: true })}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item md={12} xs={12} sx={{ mx: "auto", mt: 1 }}>
										<TextField
											InputLabelProps={{
												shrink: true,
											}}
											disabled
											fullWidth
											size='small'
											sx={{ width: "100%" }}
											id='outlined-basic'
											type={"text"}
											name='Username'
											label='Username'
											value={user?.url}
										/>
									</Grid>
									<Grid item md={12} xs={12} sx={{ mx: "auto", mt: 1 }}>
										<Button
											type='submit'
											variant='contained'
											className='button border'
											sx={{
												my: 0.5,
												py: 0.5,
												border: "2px solid",
												backgroundColor: "transparent",
											}}>
											Update Profile
										</Button>
									</Grid>
								</Grid>
							</form>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !databyEmail}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default EditProfile;
