import {
	Box,
	Container,
	Divider,
	Grid,
	Pagination,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";

const Video = () => {
	const { data, info } = useAPI();
	const [video, setVideo] = useState();
	React.useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/collectionOfVideos`)
			.then((res) => res.json())
			.then((data) => setVideo(data));
	}, []);
	const [page, setPage] = useState(0);
	return (
		<Container sx={{ pt: 5 }}>
			{data?._id ? (
				<>
					{data?.headline?.video === "off" ? (
						""
					) : (
						<>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography
									className='textColor'
									sx={{ fontWeight: 900 }}
									variant='h4'
									component='div'
									gutterBottom>
									{data?.headline?.video}
									<Divider className={`${info?.hr}`} />
								</Typography>
							</Box>
							{video && (
								<Grid container spacing={2}>
									<Grid item md={9} xs={12} sx={{ mx: "auto" }}>
										{video[page]?.videoLink?.trim() !== "" && (
											<ReactPlayer
												key={page}
												style={{ backgroundColor: "black" }}
												className='borderColor react-player'
												width='100%'
												url={video[page]?.videoLink}
												controls
											/>
										)}
									</Grid>
									<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
										<Typography
											className='textColor'
											sx={{ fontWeight: 900 }}
											variant='h5'
											component='div'>
											{video[page]?.videoTitle}
										</Typography>
									</Grid>
									<Grid
										className='pagination'
										item
										md={9}
										xs={12}
										sx={{ mx: "auto" }}>
										<Pagination
											sx={{
												display: "flex",
												justifyContent: "center !important",
											}}
											count={video?.length}
											onChange={(e, value) => setPage(value - 1)}
											variant='outlined'
											shape='rounded'
										/>
									</Grid>
								</Grid>
							)}
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default Video;
