import {
	Backdrop,
	Box,
	CircularProgress,
	Fade,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "70vw", sm: "80vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const RecrutedByUser = ({ me, openRecruted, setOpenRecruted }) => {
	const [allusers, setallUsers] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/allusers`)
			.then((res) => res.json())
			.then((data) => {
				setallUsers(data.data);
			});
	}, []);
	const [users, setUsers] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/allusers`)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data.data?.filter((u) => u?.recuretedBy === me));
			});
	}, [me]);
	let count = 1;
	const [search, setSearch] = React.useState("");
	const handleChange = (event) => {
		setSearch(event.target.value);
	};
	const [newList, setNewList] = React.useState([]);
	useEffect(() => {
		const searchs = users
			?.reverse()
			.filter(
				(user) =>
					user?.name?.toLowerCase().includes(search.toLowerCase()) ||
					user?.email?.toLowerCase().includes(search.toLowerCase()) ||
					user?.phone?.toLowerCase().includes(search.toLowerCase()) ||
					user?.url?.toLowerCase().includes(search.toLowerCase()),
			);
		setNewList(searchs);
	}, [search, users]);
	const [sells, setSells] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/successfullSells`)
			.then((res) => res.json())
			.then((data) => {
				setSells(data?.data);
			});
	}, []);
	const [forCount, setForCount] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/invitatedusers`)
			.then((res) => res.json())
			.then((data) => {
				setForCount(data?.data);
			});
	}, []);

	let inviterTotal = 0;
	let inviterRecruterTotal = 0;
	function getTotal(url) {
		const filter = sells?.filter((item) => item.invitedRecrutedBy === url);
		const filter2 = sells?.filter((item) => item.invitedBy === url);
		filter?.forEach((element) => {
			inviterRecruterTotal += parseFloat(element?.recruterPaidAmount);
		});
		filter2?.forEach((element) => {
			inviterTotal += parseFloat(element?.paidAmount);
		});
		return inviterRecruterTotal + inviterTotal;
	}
	return (
		<div>
			{users ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={openRecruted}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={openRecruted}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setOpenRecruted(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Box sx={{ height: "80px", mx: 2 }}>
								<Typography
									className='textColor'
									sx={{ fontWeight: "bold" }}
									variant='h5'>
									All Recruted By Me
								</Typography>
								<TextField
									size='small'
									sx={{ width: "100%", maxWidth: "400px" }}
									id='outlined-name'
									placeholder='Search by Name / Email / Phone / Username'
									value={search}
									onChange={handleChange}
								/>
							</Box>
							<Paper
								className='container'
								sx={{
									overflow: "auto",
									maxHeight: "calc(90vh - 80px)",
									maxWidth: "vw",
								}}>
								<Table size='medium' stickyHeader aria-label='sticky table'>
									<TableHead sx={{ th: { fontWeight: "bold" } }}>
										<TableRow>
											<TableCell align='left'>No</TableCell>
											<TableCell align='left'>Name</TableCell>
											<TableCell align='left'>Email</TableCell>
											<TableCell align='left'>Phone</TableCell>
											<TableCell align='left'>Joined On</TableCell>{" "}
											<TableCell align='left'>Appoinment Count</TableCell>
											<TableCell align='left'>Appoinment Success</TableCell>
											<TableCell align='left'>Recruit Count</TableCell>
											<TableCell align='left'>Recruit Bonus Count</TableCell>
											<TableCell align='left'>Total Earned</TableCell>
										</TableRow>
									</TableHead>
									{users?.length > 0 ? (
										<TableBody sx={{ td: { py: 1 } }}>
											{newList?.map((user, key) => (
												<TableRow
													key={key}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}>
													<TableCell align='left'>{count++}</TableCell>
													<TableCell align='left'>
														{user?.headline?.title}
													</TableCell>
													<TableCell align='left'>{user?.email}</TableCell>
													<TableCell align='left'>{user?.phone}</TableCell>
													<TableCell align='left'>
														{user?.joiningTime}
													</TableCell>
													<TableCell align='center'>
														{forCount?.filter(
															(item) => item?.invitationCode === user?.url,
														)?.length || 0}
													</TableCell>
													<TableCell align='center'>
														{sells?.filter(
															(item) => item?.invitedBy === user?.url,
														)?.length || 0}
													</TableCell>
													<TableCell align='center'>
														{allusers?.filter(
															(item) => item?.recuretedBy === user?.url,
														)?.length || 0}
													</TableCell>
													<TableCell align='center'>
														{sells?.filter(
															(item) => item?.invitedRecrutedBy === user?.url,
														)?.length || 0}
													</TableCell>
													<TableCell align='left'>
														{getTotal(user?.url)}$
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									) : (
										<TableHead sx={{ th: { fontWeight: "bold" } }}>
											<TableRow>
												<TableCell align='left'>N/A</TableCell>
												<TableCell align='left'>N/A</TableCell>
												<TableCell align='left'>N/A</TableCell>
												<TableCell align='left'>N/A</TableCell>
												<TableCell align='left'>N/A</TableCell>
											</TableRow>
										</TableHead>
									)}
								</Table>
							</Paper>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!users}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default RecrutedByUser;
