import React from "react";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	IconButton,
	Backdrop,
	Container,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import styled from "@emotion/styled";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

const PublishNews = () => {
	const [submitting, setSubmitting] = useState(false);
	const [imageLink1, setImageLink1] = useState(null);
	const [imageLink2, setImageLink2] = useState(null);
	const [imageLink3, setImageLink3] = useState(null);
	const [imageLink4, setImageLink4] = useState(null);
	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({ news, title, video }) => {
		const data = {
			image1: imageLink1,
			image2: imageLink2,
			image3: imageLink3,
			image4: imageLink4,
			news,
			title,
			video,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		setSubmitting(true);
		axios
			.post(`https://americasolarpros.herokuapp.com/news`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Posted",
					showConfirmButton: true,
					timer: 950000,
				}).then(function () {
					reset();
					setImageLink1(null);
					setImageLink2(null);
					setImageLink3(null);
					setImageLink4(null);
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const [loading1, setLoading1] = useState(false);
	const uploadImage1 = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "NewsPhoto");
		setLoading1(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink1(file.secure_url);
		setLoading1(false);
	};
	const [loading2, setLoading2] = useState(false);
	const uploadImage2 = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "NewsPhoto");
		setLoading2(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading2(false);
	};
	const [loading3, setLoading3] = useState(false);
	const uploadImage3 = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "NewsPhoto");
		setLoading3(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink3(file.secure_url);
		setLoading3(false);
	};
	const [loading4, setLoading4] = useState(false);
	const uploadImage4 = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "NewsPhoto");
		setLoading4(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink4(file.secure_url);
		setLoading4(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	return (
		<Container sx={{ pt: 2 }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				Post News
			</Typography>
			<Grid container spacing={2}>
				<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
					<form data-aos='fade-left' onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={2} sx={{ my: 2 }}>
							<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
								<Box
									display='flex'
									flexDirection='column'
									alignItems='center'
									sx={{ mx: "auto" }}>
									<label
										className='bgColor'
										htmlFor='icon-button-file1'
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											margin: "0 9px",
											borderRadius: 5,
											cursor: "pointer",
											minWidth: "280px",
										}}>
										<Input
											accept='image/*'
											id='icon-button-file1'
											type='file'
											onChange={uploadImage1}
										/>
										<Typography
											sx={{ my: 2, ml: 2, color: "white" }}
											variant='button'
											component='div'
											gutterBottom>
											Upload Photos
										</Typography>
										<IconButton
											sx={{ mx: 2 }}
											color='primary'
											aria-label='upload picture'
											component='span'>
											<AddPhotoAlternateIcon
												fontSize='large'
												sx={{ fontWeight: "bold" }}
											/>
										</IconButton>
									</label>

									{loading1 ? (
										<Box sx={{ my: 2 }}>
											<CircularProgress className='textColor' />
										</Box>
									) : (
										<img src={imageLink1} style={{ width: "200px" }} alt='' />
									)}
								</Box>
							</Grid>
							<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
								<Box
									display='flex'
									flexDirection='column'
									alignItems='center'
									sx={{ mx: "auto" }}>
									<label
										className='bgColor'
										htmlFor='icon-button-file2'
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											margin: "0 9px",
											borderRadius: 5,
											cursor: "pointer",
											minWidth: "280px",
										}}>
										<Input
											accept='image/*'
											id='icon-button-file2'
											type='file'
											onChange={uploadImage2}
										/>
										<Typography
											sx={{ my: 2, ml: 2, color: "white" }}
											variant='button'
											component='div'
											gutterBottom>
											Upload Photos
										</Typography>
										<IconButton
											sx={{ mx: 2 }}
											color='primary'
											aria-label='upload picture'
											component='span'>
											<AddPhotoAlternateIcon
												fontSize='large'
												sx={{ fontWeight: "bold" }}
											/>
										</IconButton>
									</label>

									{loading2 ? (
										<Box sx={{ my: 2 }}>
											<CircularProgress className='textColor' />
										</Box>
									) : (
										<img src={imageLink2} style={{ width: "200px" }} alt='' />
									)}
								</Box>
							</Grid>
							<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
								<Box
									display='flex'
									flexDirection='column'
									alignItems='center'
									sx={{ mx: "auto" }}>
									<label
										className='bgColor'
										htmlFor='icon-button-file3'
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											margin: "0 9px",
											borderRadius: 5,
											cursor: "pointer",
											minWidth: "280px",
										}}>
										<Input
											accept='image/*'
											id='icon-button-file3'
											type='file'
											onChange={uploadImage3}
										/>
										<Typography
											sx={{ my: 2, ml: 2, color: "white" }}
											variant='button'
											component='div'
											gutterBottom>
											Upload Photos
										</Typography>
										<IconButton
											sx={{ mx: 2 }}
											color='primary'
											aria-label='upload picture'
											component='span'>
											<AddPhotoAlternateIcon
												fontSize='large'
												sx={{ fontWeight: "bold" }}
											/>
										</IconButton>
									</label>

									{loading3 ? (
										<Box sx={{ my: 2 }}>
											<CircularProgress className='textColor' />
										</Box>
									) : (
										<img src={imageLink3} style={{ width: "200px" }} alt='' />
									)}
								</Box>
							</Grid>
							<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
								<Box
									display='flex'
									flexDirection='column'
									alignItems='center'
									sx={{ mx: "auto" }}>
									<label
										className='bgColor'
										htmlFor='icon-button-file4'
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											margin: "0 9px",
											borderRadius: 5,
											cursor: "pointer",
											minWidth: "280px",
										}}>
										<Input
											accept='image/*'
											id='icon-button-file4'
											type='file'
											onChange={uploadImage4}
										/>
										<Typography
											sx={{ my: 2, ml: 2, color: "white" }}
											variant='button'
											component='div'
											gutterBottom>
											Upload Photos
										</Typography>
										<IconButton
											sx={{ mx: 2 }}
											color='primary'
											aria-label='upload picture'
											component='span'>
											<AddPhotoAlternateIcon
												fontSize='large'
												sx={{ fontWeight: "bold" }}
											/>
										</IconButton>
									</label>

									{loading4 ? (
										<Box sx={{ my: 2 }}>
											<CircularProgress className='textColor' />
										</Box>
									) : (
										<img src={imageLink4} style={{ width: "200px" }} alt='' />
									)}
								</Box>
							</Grid>
						</Grid>
						<TextField
							required
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Title'
							{...register("title", { required: true })}
						/>
						<TextField
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='Video Link'
							{...register("video")}
						/>
						<TextField
							required
							sx={{ width: "100%", mb: 2 }}
							id='"outlined-multiline-flexible'
							label='News'
							multiline
							rows={4}
							{...register("news", { required: true })}
						/>
						<Button
							type='submit'
							variant='contained'
							className='button border'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
							}}>
							POST
						</Button>
					</form>
				</Grid>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default PublishNews;
