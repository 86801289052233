import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import PropagateLoader from "react-spinners/PropagateLoader";
import Typewriter from "typewriter-effect";
import { useAPI } from "../../../../apiContext";

const Banner = () => {
	const { data } = useAPI();
	const [datas, setDatas] = useState();
	React.useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/ownerdata`)
			.then((res) => res.json())
			.then((data) => setDatas(data));
	}, []);
	return (
		<div>
			{data?._id ? (
				<Card
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						boxShadow: 0,
						position: "relative",
						backgroundColor: "transparent",
					}}>
					<CardMedia
						className='bannerPhoto'
						component='img'
						alt=''
						sx={{
							height: "100%",
							position: "relative",
							backgroundSize: "cover",
							objectFit: "fill",
						}}
						image={datas?.banner?.imageLink2}
					/>
					<Box
						sx={{
							height: { md: 200, xs: 150 },
							width: { md: 200, xs: 150 },
							mt: { md: -11, xs: -7 },
							position: "relative",
						}}>
						<CardMedia
							className='profilePhoto'
							sx={{
								boxShadow: 11,
								borderRadius: "50%",
								width: "100%",
								height: "100%",
							}}
							component='img'
							alt={data?.headline?.title}
							image={data?.profile?.imageLink2 || "/user.jpg"}
						/>
					</Box>
					<CardContent>
						<Typography
							className='textColor title'
							sx={{ fontWeight: 500, mb: 1, fontSize: "2.5rem" }}
							component='div'>
							{data?.headline?.title}
						</Typography>
						<Typography
							className='textColor'
							sx={{ fontWeight: 700 }}
							variant='h5'
							component='div'
							gutterBottom>
							<Typewriter
								options={{
									strings: [`${data?.headline?.subtitle || ""}`],
									autoStart: true,
									loop: true,
								}}
							/>
						</Typography>
					</CardContent>
				</Card>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</div>
	);
};

export default Banner;
