import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SingleUser from "./SingleUser";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Note from "./Note";
import { useAPI } from "../../../../apiContext";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const SuccessMeeting = () => {
	const { databyEmail } = useAPI();
	const [open, setOpen] = useState(false);
	const [openNote, setOpenNote] = useState(false);
	const [user, setUser] = useState();

	function setTime(time) {
		const previousZone = new Date(time?.split("-")[0]).toLocaleString("en-US", {
			timeZone: time?.split("-")[1],
		});
		const localZone = new Date(previousZone)?.toLocaleString("en-US", {
			timeZone: Intl.DateTimeFormat().resolvedOptions()?.timeZone,
		});
		return localZone;
	}
	const [allUsers, setAllUsers] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/meetings`)
			.then((res) => res.json())
			.then((data) => {
				setAllUsers(data?.reverse().filter((p) => p?.status === "Closed"));
			});
	}, []);

	const [filter, setFilter] = useState();
	useEffect(() => {
		setFilter(
			allUsers?.filter(
				(p) =>
					p?.invitationCode === databyEmail?.url ||
					p?.assignedTo?.split(",")[1]?.split("E:")[1] === databyEmail?.email,
			),
		);
	}, [allUsers, databyEmail?.email, databyEmail?.url]);

	const [filtered, setFiltered] = useState();
	useEffect(() => {
		setFiltered(databyEmail?.role === "Admin" ? allUsers : filter);
	}, [allUsers, filter, databyEmail?.role]);

	const columns = [
		{
			field: "scheduleId",
			headerName: "ID",
			minWidth: 150,
		},
		{
			field: "submitTime",
			headerName: "Submited On",
			minWidth: 220,
		},
		{
			field: "ScheduledFor",
			headerName: "Scheduled For",
			minWidth: 200,
			valueGetter: (params) => params?.row?.selectedDateTime,
			renderCell: (params) => {
				return setTime(params?.row?.selectedDateTime);
			},
		},
		{
			field: "closedOn",
			headerName: "Closed On",
			minWidth: 220,
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: 220,
		},
		{
			field: "userName",
			headerName: "Phone",
			minWidth: 150,
			valueGetter: (params) => params?.row?.phoneNumber,
			renderCell: (params) => {
				return (
					<a
						href={`tel:${params?.row?.phoneNumber}`}
						className='textColor'
						style={{ textDecoration: "none" }}>
						{params?.row?.phoneNumber}
					</a>
				);
			},
		},
		{
			field: "invitationCode",
			headerName: "Invited By",
			minWidth: 120,
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: 100,
		},

		{
			field: "View",
			headerName: "View",
			minWidth: 200,
			filterable: false,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Button
							onClick={() => {
								setOpen(true);
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<RemoveRedEyeIcon />
						</Button>
						<Button
							onClick={() => {
								setOpenNote(true);
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<NoteAddIcon />
						</Button>
					</Box>
				);
			},
		},
	];

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				Succeed Meetings
			</Typography>
			{filtered && (
				<Box sx={{ width: "100%", height: "80vh" }}>
					<DataGrid
						rows={filtered}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!allUsers}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && <SingleUser open={open} setOpen={setOpen} user={user} />}
			{openNote && (
				<Note openNote={openNote} setOpenNote={setOpenNote} user={user} />
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!allUsers}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default SuccessMeeting;
