import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Button,
	Grid,
	Typography,
	CircularProgress,
	Backdrop,
} from "@mui/material";
import { Box } from "@mui/system";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import Swal from "sweetalert2";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "60vw", sm: "60vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const ReSchedule = ({ dateOpen, setDateOpen, user }) => {
	const [submitting, setSubmitting] = useState(false);
	const [selectedDateTime, setSelectedDateTime] = React.useState();
	const [date, setDate] = React.useState(new Date());
	const [selectedTime, setSelectedTime] = React.useState();
	const convDateTime =
		date?.toLocaleString()?.split(" ")[0] +
		" " +
		selectedTime +
		"-" +
		Intl.DateTimeFormat().resolvedOptions().timeZone;
	useEffect(() => {
		setSelectedDateTime(convDateTime);
	}, [convDateTime]);

	const handleTimeDateSelect = () => {
		setSubmitting(true);
		axios
			.put(`https://americasolarpros.herokuapp.com/statusRescheduled/${user?._id}`, {
				status: "Pre-site-survey",
				selectedDateTime: selectedDateTime,
				submitTime: new Date().toLocaleString("en-US", {
					timeZone: "America/Los_Angeles",
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
					second: "2-digit",
					timeZoneName: "short",
				}),
			})
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
				setDateOpen(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const [selectDate, setSelectDate] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/BlockedScheduleDate`)
			.then((res) => res.json())
			.then((data) => {
				setSelectDate(data);
			});
	}, []);

	const dates = selectDate?.map((day) => day.selectedDate).join(",");
	const customDayRenderer = (date, selectedDates, pickersDayProps) => {
		const stringifiedDate = date?.toLocaleString()?.split(", ")[0];
		if (dates?.includes(stringifiedDate)) {
			return <PickersDay {...pickersDayProps} disabled />;
		}
		return <PickersDay {...pickersDayProps} />;
	};

	const [manageScheduleTime, setManageScheduleTime] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/ManageScheduleTime`)
			.then((res) => res.json())
			.then((data) => {
				setManageScheduleTime(
					data?.filter(
						(item) =>
							item?.day === date?.toLocaleDateString("en", { weekday: "long" }),
					),
				);
			});
	}, [date]);

	function timeConversion(time) {
		time = time
			?.toString()
			?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
		if (time?.length > 1) {
			time = time?.slice(1);
			time[5] = +time[0] < 12 ? " AM" : " PM";
			time[0] = +time[0] % 12 || 12;
		}
		return time?.join("");
	}
	return (
		<div>
			{user ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={dateOpen}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={dateOpen}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setDateOpen(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Typography
								variant='h4'
								gutterBottom
								className='textColor'
								sx={{ fontWeight: "bold" }}>
								Reschedule
							</Typography>

							<Grid container spacing={2} className='schedule'>
								<Grid item xs={12} md={7} className='calander'>
									{selectDate && (
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<CalendarPicker
												renderDay={customDayRenderer}
												disablePast
												date={date}
												onChange={(newDate) => setDate(newDate)}
											/>
										</LocalizationProvider>
									)}
								</Grid>
								<Grid item xs={12} md={5}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											maxHeight: "380px",
											overflowY: "auto",
										}}>
										{manageScheduleTime
											?.sort(
												(a, b) =>
													new Date("1970/01/01 " + a.time) -
													new Date("1970/01/01 " + b.time),
											)
											?.map((time, index) => (
												<Button
													key={index}
													className={
														timeConversion(selectedTime) ===
															timeConversion(time?.time) && "selectedTime"
													}
													onClick={() =>
														setSelectedTime(timeConversion(time?.time))
													}
													variant='outlined'
													sx={{
														my: 0.3,
														p: 1,
														fontWeight: "bold",
													}}>
													{timeConversion(time?.time)}
												</Button>
											))}
									</Box>
								</Grid>
								<Grid
									item
									xs={12}
									md={12}
									display='flex'
									justifyContent='flex-end'>
									{selectedTime && date && (
										<Button
											onClick={() => handleTimeDateSelect(selectedTime, date)}
											className='confirm'
											sx={{ width: "180px" }}>
											Confirm
										</Button>
									)}
								</Grid>
							</Grid>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !user}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default ReSchedule;
