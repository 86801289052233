import {
	Backdrop,
	CircularProgress,
	Container,
	Typography,
} from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useEffect } from "react";

const FAQ = () => {
	const [faqs, setFaqs] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/faq`)
			.then((res) => res.json())
			.then((data) => {
				setFaqs(data?.reverse());
			});
	}, []);
	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				className='textColor'
				sx={{ my: 1, fontWeight: "bold" }}
				variant='h4'
				component='div'>
				FAQ
			</Typography>
			{faqs?.map((faq) => (
				<Accordion
					sx={{
						my: 1,
					}}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon sx={{ color: "white !important" }} />}
						aria-controls='panel1a-content'
						id='panel1a-header'>
						<Typography>{faq?.question}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className='textColor'>{faq?.answer}</Typography>
					</AccordionDetails>
				</Accordion>
			))}

			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={!faqs}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default FAQ;
