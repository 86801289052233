import { Button, Card, Chip, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import FaceIcon from "@mui/icons-material/Face";
import { Link } from "react-router-dom";

const TeamInfoAdmin = () => {
	const [regions, setRegions] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/regions`)
			.then((res) => res.json())
			.then((data) => {
				setRegions(data);
			});
	}, []);
	const [managers, setManagers] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/managers`)
			.then((res) => res.json())
			.then((data) => {
				setManagers(data);
			});
	}, []);
	const [teamLeads, setTeamLeads] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/teamLeads`)
			.then((res) => res.json())
			.then((data) => {
				setTeamLeads(data);
			});
	}, []);
	const [trainers, setTrainers] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/trainers`)
			.then((res) => res.json())
			.then((data) => {
				setTrainers(data);
			});
	}, []);
	const [selectRegionTree, setSelectRegionTree] = useState();
	const [selectManagerTree, setSelectManagerTree] = useState();
	const [selectTeamLeadTree, setSelectTeamLeadTree] = useState();
	return (
		<Box sx={{ p: 2 }}>
			<Typography
				className='textColor'
				variant='h5'
				sx={{ fontWeight: "bold", mb: 2 }}>
				Regionals
			</Typography>
			<Grid container spacing={2}>
				{regions?.map((r) => (
					<Grid item md={4} xs={6}>
						<Card sx={{ padding: 1.5, position: "relative" }}>
							<Button
								onClick={() => setSelectRegionTree(r?.regionTeam)}
								sx={{ width: "90%" }}
								className={
									r?.regionTeam === selectRegionTree && "activeBtnPayment"
								}>
								"{r?.regionTeam}" Leaders
							</Button>
							<CancelIcon
								className='textColor'
								onClick={() => {
									setSelectRegionTree(null);
									setSelectManagerTree(null);
									setSelectTeamLeadTree(null);
								}}
								sx={{
									cursor: "pointer",
									position: "absolute",
									right: 2,
									top: 2,
									zIndex: 999,
								}}
							/>
							<Box
								sx={{
									display: "flex",
									mt: 1,
									flexWrap: "wrap",
									justifyContent: "center",
								}}>
								{r?.teamLeaders?.map((s) => (
									<>
										<Link
											to={`/dashboard/TeamInfo/${s?.url}`}
											style={{
												textDecoration: "none",
											}}>
											<Chip
												icon={<FaceIcon sx={{ color: "white !important" }} />}
												label={s?.url}
												variant='outlined'
												className='bgColor'
												sx={{
													color: "white",
													cursor: "pointer",
												}}
											/>
										</Link>
									</>
								))}
							</Box>
						</Card>
					</Grid>
				))}
			</Grid>
			{selectRegionTree && (
				<>
					<Divider
						sx={{ mt: 1, border: "1px solid" }}
						className='borderColor'
					/>
					<Typography
						className='textColor'
						variant='h5'
						sx={{ fontWeight: "bold", mb: 2 }}>
						Managers
					</Typography>
				</>
			)}
			<Grid container spacing={2}>
				{managers
					?.filter((f) => f?.underRegionTeam === selectRegionTree)
					.map((m) => (
						<Grid item md={4} xs={6}>
							<Card sx={{ padding: 1.5, position: "relative" }}>
								<Button
									onClick={() => setSelectManagerTree(m?.managerTeam)}
									sx={{ width: "90%" }}
									className={
										m?.managerTeam === selectManagerTree && "activeBtnPayment"
									}>
									"{m?.managerTeam}" Leaders
								</Button>
								<CancelIcon
									className='textColor'
									onClick={() => {
										setSelectManagerTree(null);
										setSelectTeamLeadTree(null);
										/* setSelectTrainerTree(null); */
									}}
									sx={{
										cursor: "pointer",
										position: "absolute",
										right: 2,
										top: 2,
										zIndex: 999,
									}}
								/>
								<Box
									sx={{
										display: "flex",
										mt: 1,
										flexWrap: "wrap",
										justifyContent: "center",
									}}>
									{m?.teamLeaders?.map((s) => (
										<>
											<Link
												to={`/dashboard/TeamInfo/${s?.url}`}
												style={{
													textDecoration: "none",
												}}>
												<Chip
													icon={<FaceIcon sx={{ color: "white !important" }} />}
													label={s?.url}
													variant='outlined'
													className='bgColor'
													sx={{
														color: "white",
														cursor: "pointer",
													}}
												/>
											</Link>
										</>
									))}
								</Box>
							</Card>
						</Grid>
					))}
			</Grid>
			{selectManagerTree && (
				<>
					<Divider
						sx={{ mt: 1, border: "1px solid" }}
						className='borderColor'
					/>
					<Typography
						className='textColor'
						variant='h5'
						sx={{ fontWeight: "bold", mb: 2 }}>
						Team Leads
					</Typography>
				</>
			)}

			<Grid container spacing={2}>
				{teamLeads
					?.filter((f) => f?.underManagerTeam === selectManagerTree)
					.map((t) => (
						<Grid item md={4} xs={6}>
							<Card sx={{ padding: 1.5, position: "relative" }}>
								<Button
									onClick={() => setSelectTeamLeadTree(t?.teamLead)}
									sx={{ width: "90%" }}
									className={
										t?.teamLead === selectTeamLeadTree && "activeBtnPayment"
									}>
									"{t?.teamLead}" Leaders
								</Button>
								<CancelIcon
									className='textColor'
									onClick={() => {
										setSelectTeamLeadTree(null);
									}}
									sx={{
										cursor: "pointer",
										position: "absolute",
										right: 2,
										top: 2,
										zIndex: 999,
									}}
								/>
								<Box
									sx={{
										display: "flex",
										mt: 1,
										flexWrap: "wrap",
										justifyContent: "center",
									}}>
									{t?.teamLeaders?.map((s) => (
										<>
											<Link
												to={`/dashboard/TeamInfo/${s?.url}`}
												style={{
													textDecoration: "none",
												}}>
												<Chip
													icon={<FaceIcon sx={{ color: "white !important" }} />}
													label={s?.url}
													variant='outlined'
													className='bgColor'
													sx={{
														color: "white",
														cursor: "pointer",
													}}
												/>
											</Link>
										</>
									))}
								</Box>
							</Card>
						</Grid>
					))}
			</Grid>
			{selectTeamLeadTree && (
				<>
					<Divider
						sx={{ mt: 1, border: "1px solid" }}
						className='borderColor'
					/>
					<Typography
						className='textColor'
						variant='h5'
						sx={{ fontWeight: "bold", mb: 2 }}>
						Trainers
					</Typography>
				</>
			)}
			<Grid container spacing={2}>
				{trainers
					?.filter((f) => f?.underTeamLeadTeam === selectTeamLeadTree)
					.map((t) => (
						<Grid item md={4} xs={6}>
							<Card sx={{ padding: 1.5, position: "relative" }}>
								<Button sx={{ width: "90%" }}>"{t?.trainer}" Leaders</Button>
								<Box
									sx={{
										display: "flex",
										mt: 1,
										flexWrap: "wrap",
										justifyContent: "center",
									}}>
									{t?.teamLeaders?.map((s) => (
										<>
											<Link
												to={`/dashboard/TeamInfo/${s?.url}`}
												style={{
													textDecoration: "none",
												}}>
												<Chip
													icon={<FaceIcon sx={{ color: "white !important" }} />}
													label={s?.url}
													variant='outlined'
													className='bgColor'
													sx={{
														color: "white",
														cursor: "pointer",
													}}
												/>
											</Link>
										</>
									))}
								</Box>
								<Divider
									sx={{ mt: 1, border: "1px solid" }}
									className='borderColor'
								/>
								<Button sx={{ width: "90%", my: 1 }}>
									"{t?.trainer}" Agents
								</Button>
								<Box
									sx={{
										display: "flex",
										flexWrap: "wrap",
										justifyContent: "center",
									}}>
									{t?.trainerAgents?.map((s) => (
										<>
											<Link
												to={`/dashboard/TeamInfo/${s?.url}`}
												style={{
													textDecoration: "none",
												}}>
												<Chip
													icon={<FaceIcon sx={{ color: "white !important" }} />}
													label={s?.url}
													variant='outlined'
													className='bgColor'
													sx={{
														color: "white",
														cursor: "pointer",
													}}
												/>
											</Link>
										</>
									))}
								</Box>
							</Card>
						</Grid>
					))}
			</Grid>
		</Box>
	);
};

export default TeamInfoAdmin;
