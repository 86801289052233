import React from "react";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import ScheduleQuestions from "./ScheduleQuestions";
import ScheduleSelectProduct from "./ScheduleSelectProduct";

const ScheduleDashboard = () => {
	const [selectProduct, setSelectProduct] = React.useState();
	const date = new Date();
	const selectedDateTime =
		date.toLocaleString([], {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
		}) +
		"-" +
		Intl.DateTimeFormat().resolvedOptions().timeZone;

	return (
		<Container className='schedule'>
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				minHeight='100vh'>
				<Grid container spacing={2} justifyContent='center'>
					{!selectProduct && (
						<ScheduleSelectProduct setSelectProduct={setSelectProduct} />
					)}
					{selectProduct && (
						<ScheduleQuestions
							selectedDateTime={selectedDateTime}
							selectProduct={selectProduct}
						/>
					)}
				</Grid>
			</Box>
		</Container>
	);
};

export default ScheduleDashboard;
