import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Backdrop,
	Box,
	IconButton,
	Button,
	CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import { styled } from "@mui/material/styles";
import GetAuth from "../../../GetAuth";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const Galleryphoto = () => {
	const [submitting, setSubmitting] = useState(false);
	const [imageLink2, setImageLink2] = useState(null);
	const [deleted, setDeleted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = React.useState();
	const [user] = GetAuth();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/gallery`)
			.then((res) => res.json())
			.then((data) => {
				setData(data?.reverse());
			});
	}, [submitting, deleted, user?.email]);
	const handleDelete = (id) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(`https://americasolarpros.herokuapp.com/gallery/${id}`)
					.then(function (response) {
						Swal.fire(
							"Deleted!",
							"That gallery image has been deleted.",
							"success",
						);
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "Gallery");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});

	const update = () => {
		const data = {
			imageLink2,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
			galleryId: `gallery${Math.floor(100000 + Math.random() * 900000)}`,
		};
		setSubmitting(true);
		axios
			.post(`https://americasolarpros.herokuapp.com/gallery`, data)
			.then(function (response) {
				setSubmitting(false);
				setImageLink2(null);
				Swal.fire({
					icon: "success",
					title: "Your photo uploaded Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const columns = [
		{
			field: "imageLink2",
			headerName: "Image",
			flex: 1,
			renderCell: (params) => {
				return (
					<img
						src={params?.row?.imageLink2}
						alt=''
						width='90px'
						height='90px'
					/>
				);
			},
		},

		{
			field: "submitTime",
			headerName: "Uploaded",
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 170,
			filterable: false,
			renderCell: (params) => {
				return (
					<Button
						className='button border'
						onClick={() => handleDelete(params?.row?._id)}
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "transparent",
							borderRadius: "25px",
						}}
						variant='contained'>
						<DeleteIcon />
					</Button>
				);
			},
		},
	];
	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Update gallery
			</Typography>

			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				sx={{ my: 1, mx: "auto" }}>
				{!imageLink2 && !loading && (
					<label
						className='bgColor'
						htmlFor='icon-button-file'
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							margin: "0 9px",
							borderRadius: 5,
						}}>
						<Input
							accept='image/*'
							id='icon-button-file'
							type='file'
							onChange={uploadImage}
						/>
						<Typography
							sx={{ my: 2, ml: 2, color: "white" }}
							variant='body1'
							component='div'
							gutterBottom>
							Upload New Gallery Picture*
						</Typography>
						<IconButton
							sx={{ mx: 2 }}
							color='primary'
							aria-label='upload picture'
							component='span'>
							<AttachFileIcon fontSize='large' sx={{ fontWeight: "bold" }} />
						</IconButton>
					</label>
				)}

				{loading ? (
					<Box sx={{ my: 2 }}>
						<CircularProgress className='textColor' />
					</Box>
				) : (
					<>
						{imageLink2 && (
							<img src={imageLink2} style={{ width: "300px" }} alt='' />
						)}
					</>
				)}
			</Box>
			{imageLink2 && (
				<Button
					onClick={update}
					variant='contained'
					component='span'
					className='button border'
					sx={{
						my: 1,
						py: 0.5,
						width: "150px",
						border: "2px solid",
						backgroundColor: "transparent",
					}}>
					Post
				</Button>
			)}
			{data && (
				<Box sx={{ width: "100%", height: "70vh" }}>
					<DataGrid
						rows={data}
						getRowId={(row) => row?.galleryId}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}
			{/* 	<Grid>
					<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "85vh", maxWidth: "90vw", m: 2 }}>
					<Table size='small' stickyHeader aria-label='sticky table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='center' colSpan={4}>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h4'>
										All Photos
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Image</TableCell>
								<TableCell align='left'>Uploaded</TableCell>
								<TableCell align='left'>Action</TableCell>
							</TableRow>
						</TableHead>
						{data?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{data?.map((image, key) => (
									<TableRow
										key={key}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>
											<img src={image?.imageLink2} alt='' width='100px' />
										</TableCell>
										<TableCell align='left'>{image?.submitTime}</TableCell>
										<TableCell align='left'>
											<Button
												className='button border'
												onClick={() => handleDelete(image?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>{" "} */}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!data || submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default Galleryphoto;
