import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { styled } from "@mui/material/styles";
import Switch from "react-switch";
import axios from "axios";
import Swal from "sweetalert2";
import GetAuth from "../../../GetAuth";

const Venmo = ({ data }) => {
	const [user] = GetAuth();
	const [imageLink2, setImageLink2] = useState(null);
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			qrImage: "",
			venmoEmail: "",
			venmoPhone: "",
			venmoUsername: "",
		},
	});
	const [submitting, setSubmitting] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "Payment");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	const [datas, setDatas] = useState();
	const [checked, setChecked] = React.useState(
		datas?.defaultMethod === "Venmo" ? true : false,
	);
	React.useEffect(() => {
		axios
			.get(
				`https://americasolarpros.herokuapp.com/paymentInfo/${
					data?.url || data?.user
				}`,
			)
			.then((res) => {
				reset(res.data?.venmoInfo);
				setDatas(res.data);
			});
	}, [reset, data, submitting, checked]);
	const onSubmit = (data) => {
		setSubmitting(true);
		const info = { ...data, qrImage: imageLink2 };
		const submitTime = new Date().toLocaleString("en-US", {
			timeZone: "America/Los_Angeles",
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			timeZoneName: "short",
		});
		axios
			.put(`https://americasolarpros.herokuapp.com/paymentInfoVenmo/${datas?._id}`, {
				info,
				submitTime,
			})
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleChange = (nextChecked) => {
		setSubmitting(true);
		axios
			.put(`https://americasolarpros.herokuapp.com/default/${datas?._id}`, {
				defaultMethod: nextChecked === true && "Venmo",
			})
			.then(function (response) {
				setSubmitting(false);
				setChecked(nextChecked);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container>
			<p className='textColor'>Last Updated: {datas?.submitTime}</p>
			{data?.email === user?.email && (
				<Box
					className='borderColor'
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						border: "3px solid",
						width: "200px",
						mx: "auto",
						py: 0.7,
						mb: 1,
					}}>
					<Typography
						className='textColor'
						sx={{ fontWeight: "bold", mr: 1 }}
						variant='body1'>
						Set As Default
					</Typography>
					{datas && (
						<Switch
							onChange={handleChange}
							checked={datas?.defaultMethod === "Venmo" ? true : false}
							className='react-switch'
						/>
					)}
				</Box>
			)}
			<img src='/venmo.png' alt='' style={{ width: "50px", height: "50px" }} />
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold", mb: 1 }}
				variant='h4'>
				Venmo
			</Typography>

			<form onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid
					container
					spacing={1}
					sx={{ mb: 2, justifyContent: "center !important", mx: "auto" }}>
					{data?.email === user?.email && (
						<Grid item md={3} xs={12}>
							<Box
								display='flex'
								alignItems='center'
								justifyContent='center'
								sx={{ mb: 2 }}>
								{!imageLink2 && (
									<label
										className='bgColor'
										htmlFor='icon-button-file'
										style={{
											display: "flex",
											alignItems: "center",
											flexDirection: "column",
											justifyContent: "center",
											borderRadius: 5,
											width: "150px",
											height: "150px",
										}}>
										<Input
											accept='image/*'
											id='icon-button-file'
											type='file'
											onChange={uploadImage}
										/>
										<Typography
											sx={{ my: 1, color: "white" }}
											variant='body1'
											component='div'
											gutterBottom>
											Upload QR Code*
										</Typography>
										<IconButton
											sx={{ mx: 2 }}
											color='primary'
											aria-label='upload picture'
											component='span'>
											<QrCode2Icon
												fontSize='large'
												sx={{ fontWeight: "bold" }}
											/>
										</IconButton>
									</label>
								)}
							</Box>
						</Grid>
					)}
					{(loading || imageLink2) && (
						<Grid item md={3} xs={12}>
							{loading ? (
								<Box sx={{ m: 2 }}>
									<CircularProgress className='textColor' />
								</Box>
							) : (
								<>
									{!loading && imageLink2 && (
										<img
											src={imageLink2}
											style={{ width: "150px", height: "150px" }}
											alt=''
										/>
									)}
								</>
							)}
						</Grid>
					)}
					{datas?.venmoInfo?.qrImage && !loading && !imageLink2 && (
						<Grid item md={3} xs={12}>
							<Box sx={{ mx: "auto" }}>
								<img
									src={datas?.venmoInfo?.qrImage}
									style={{ width: "150px", height: "150px" }}
									alt=''
								/>
							</Box>
						</Grid>
					)}
				</Grid>

				<Grid container spacing={2}>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ width: "100%" }}
							id='outlined-basic'
							type={"email"}
							name='Email Account'
							label='Email Account'
							{...register("venmoEmail", { required: true })}
						/>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ width: "100%" }}
							id='outlined-basic'
							type={"text"}
							name='Venmo Username'
							label='Venmo Username'
							{...register("venmoUsername", { required: true })}
						/>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ width: "100%" }}
							id='outlined-basic'
							type={"tel"}
							name='Phone Number'
							label='Phone Number'
							placeholder='+1-212-456-7890'
							{...register("venmoPhone", { required: true })}
						/>
					</Grid>
					{data?.email === user?.email && (
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							{imageLink2 && (
								<Button
									type='submit'
									variant='contained'
									className='button border'
									sx={{
										my: 1,
										py: 0.5,
										width: "150px",
										border: "2px solid",
										backgroundColor: "transparent",
									}}>
									Submit
								</Button>
							)}
						</Grid>
					)}
				</Grid>
			</form>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting || !datas}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Venmo;
