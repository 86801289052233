import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import PhotoIcon from "@mui/icons-material/Photo";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const ManageProducts = () => {
	const [deleted, setDeleted] = useState(false);
	const [products, setProducts] = useState();
	const [imageLink2, setImageLink2] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/products`)
			.then((res) => res.json())
			.then((data) => {
				setProducts(data?.reverse());
			});
	}, [deleted, submitting]);
	const handleDelete = (id) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(`https://americasolarpros.herokuapp.com/deleteProducts/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That product has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "Products");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});

	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({ product, potentialCommission }) => {
		setSubmitting(true);
		const data = {
			name: product,
			potentialCommission: potentialCommission,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
			image: imageLink2,
			questions: [],
			inShort: "product" + Math.floor(100000 + Math.random() * 900000),
		};
		axios
			.post(`https://americasolarpros.herokuapp.com/products`, data)
			.then(function (response) {
				setSubmitting(false);
				setImageLink2(null);
				reset();
				Swal.fire({
					icon: "success",
					title: "Successfully Added",
					showConfirmButton: true,
					timer: 2500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const columns = [
		{
			field: "image",
			headerName: "Image",
			minWidth: 150,
			filterable: false,
			renderCell: (params) => {
				return <img src={params?.row?.image} alt='' width='100px' />;
			},
		},
		{
			field: "name",
			headerName: "Products",
			flex: 1,
		},
		{
			field: "potentialCommission",
			headerName: "Potential Commission",
			minWidth: 150,
		},
		{
			field: "QuestionsUpdate",
			headerName: "Questions Update",
			filterable: false,
			minWidth: 150,
			renderCell: (params) => {
				return (
					<Link
						style={{
							textDecoration: "none",
							color: "white",
						}}
						to={`/dashboard/manageproducts/${params?.row?._id}`}>
						<Button
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							Questions
						</Button>
					</Link>
				);
			},
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 50,
			filterable: false,
			renderCell: (params) => {
				return (
					<Button
						className='button border'
						onClick={() => handleDelete(params?.row?._id)}
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'>
						<DeleteIcon />
					</Button>
				);
			},
		},
	];

	const [open, setOpen] = useState(false);
	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ mb: 1, fontWeight: "bold" }}
				variant='h4'
				component='div'
				gutterBottom>
				Add New Product
			</Typography>
			<Button
				onClick={() => (open ? setOpen(false) : setOpen(true))}
				variant='contained'
				type='submit'
				sx={{
					mb: 1,
					px: 3,
					fontWeight: "bold",
					borderRadius: "25px",
					backgroundColor: open && "red !important",
				}}>
				{open ? "Close" : "Add New"}
			</Button>
			{open && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2}>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<Box
								display='flex'
								flexDirection='column'
								alignItems='center'
								sx={{ my: 1, mx: "auto" }}>
								<label
									className='bgColor'
									htmlFor='icon-button-file'
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										margin: "0 9px",
										borderRadius: 5,
										cursor: "pointer",
										minWidth: "280px",
									}}>
									<Input
										accept='image/*'
										id='icon-button-file'
										type='file'
										onChange={uploadImage}
									/>
									<Typography
										sx={{ my: 2, ml: 2, color: "white" }}
										variant='button'
										component='div'
										gutterBottom>
										Product Photo
									</Typography>
									<IconButton
										sx={{ mx: 2 }}
										color='primary'
										aria-label='upload picture'
										component='span'>
										<PhotoIcon fontSize='large' sx={{ fontWeight: "bold" }} />
									</IconButton>
								</label>

								{loading ? (
									<Box sx={{ my: 2 }}>
										<CircularProgress className='textColor' />
									</Box>
								) : (
									<img src={imageLink2} style={{ width: "200px" }} alt='' />
								)}
							</Box>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								required
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='Product Name'
								label='Product Name'
								{...register("product", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								required
								sx={{ width: "100%" }}
								id='outlined-basic'
								type='number'
								name='Potential Commission'
								label='Potential Commission'
								{...register("potentialCommission", { required: true })}
							/>
						</Grid>
						{/* <Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							required
							sx={{ width: "100%" }}
							id='outlined-basic'
							name='Want Bill Photo?'
							label='Want Bill Photo? (yes/no)'
							{...register("billPhoto", { required: true })}
						/>
					</Grid> */}
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<Button
								className='sendButton'
								type='submit'
								variant='contained'
								sx={{
									width: "100%",
									mb: 2,
									px: 3,
									fontWeight: "bold",
									borderRadius: "25px",
								}}>
								Post
							</Button>
						</Grid>
					</Grid>
				</form>
			)}
			{products && (
				<Box sx={{ width: "100%", height: "70vh" }}>
					<DataGrid
						rows={products}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}
			{/* 	<>
				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' stickyHeader aria-label='sticky table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='center' colSpan={6}>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h4'>
										All Products
									</Typography>
									<Typography className='textColor'>
										Last Added in {products?.[0]?.submitTime || "N/A"}
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Image</TableCell>
								<TableCell align='left'>Products</TableCell>
								<TableCell align='center'>Potential Commission</TableCell>
								<TableCell align='center'>Questions Update</TableCell>
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{products?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{products?.reverse().map((sProduct, key) => (
									<TableRow
										key={key}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>
											<img src={sProduct?.image} alt='' width='100px' />
										</TableCell>
										<TableCell align='left'>{sProduct?.name}</TableCell>
										<TableCell align='center'>
											{sProduct?.potentialCommission}
										</TableCell>
										<TableCell align='center'>
											<Link
												style={{
													textDecoration: "none",
													color: "white",
												}}
												to={`/dashboard/manageproducts/${sProduct?._id}`}>
												<Button
													className='button border'
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													Questions
												</Button>
											</Link>
										</TableCell>
										<TableCell align='center'>
											<Button
												className='button border'
												onClick={() => handleDelete(sProduct?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='center'>N/A</TableCell>
									<TableCell align='center'>N/A</TableCell>
									<TableCell align='center'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</> */}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !products}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default ManageProducts;
