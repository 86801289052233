import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box } from "@mui/system";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SellIcon from "@mui/icons-material/Sell";

const ScheduleDetails = ({
	selectedDateTime,
	setNextStep,
	setSelectProduct,
	selectProduct,
	setSelectedDateTime,
}) => {
	return (
		<>
			<Grid item xs={12} lg={5}>
				{selectProduct && !selectedDateTime && (
					<ArrowBackIcon
						className=' bgColor'
						sx={{
							mb: 1.5,
							p: 0.5,
							borderRadius: 5,
							color: "white",
							cursor: "pointer",
						}}
						onClick={() => {
							setSelectProduct();
						}}
					/>
				)}
				{selectProduct && selectedDateTime && (
					<ArrowBackIcon
						className=' bgColor'
						sx={{
							mb: 1.5,
							p: 0.5,
							borderRadius: 5,
							color: "white",
							cursor: "pointer",
						}}
						onClick={() => {
							setNextStep(false);
							setSelectedDateTime();
						}}
					/>
				)}
				<br />
				<img
					style={{ width: "70px", height: "70px", borderRadius: "50%" }}
					src='https://d3v0px0pttie1i.cloudfront.net/uploads/user/avatar/17835503/c4ce87ae.png'
					alt=''
				/>
				<br />
				<Typography variant='button' sx={{ fontWeight: "bold", my: 1 }}>
					America Solar
				</Typography>
				<Typography variant='h5' sx={{ fontWeight: "bold", my: 1 }}>
					Get a free quote
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
					<AccessTimeIcon sx={{ mr: 1 }} />
					<Typography variant='button' sx={{ fontWeight: "bold" }}>
						Anytime
					</Typography>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
					<CallIcon sx={{ mr: 1 }} />
					<Typography variant='button' sx={{ fontWeight: "bold" }}>
						Phone Call
					</Typography>
				</Box>
				{selectedDateTime && (
					<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
						<EventNoteIcon sx={{ mr: 1 }} />
						<Typography variant='button' sx={{ fontWeight: "bold" }}>
							{selectedDateTime}
						</Typography>
					</Box>
				)}
				{selectProduct && (
					<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
						<SellIcon sx={{ mr: 1 }} />
						<Typography variant='button' sx={{ fontWeight: "bold" }}>
							{selectProduct?.name}
						</Typography>
					</Box>
				)}
				<Typography
					variant='body2'
					gutterBottom
					sx={{ my: 2, lineHeight: 1.5 }}>
					Select the product you would like to receive a free quote. Complete
					the form and an America Solar specialist will contact you
					with a free quote
				</Typography>
			</Grid>
		</>
	);
};

export default ScheduleDetails;
