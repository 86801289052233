import axios from "axios";
import React, { useState } from "react";

const UseData = () => {
	const [info, setInfo] = useState();
	React.useEffect(() => {
		axios
			.get(`https://americasolarpros.herokuapp.com/colorBorder`)
			.then((res) => {
				setInfo(res.data?.colorBorder);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	const [data, setData] = useState();
	React.useEffect(() => {
		const url = window.location.pathname.split("/")[1];
		fetch(`https://americasolarpros.herokuapp.com/alluser/${url || "owner"}`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	return [data, info];
};

export default UseData;
