import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import axios from "axios";
import { Button } from "@mui/material";

const DeleteFunction = ({ setSubmitting, databyEmail, params }) => {
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				axios
					.delete(`https://americasolarpros.herokuapp.com/invitatedusers/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That item has been deleted.", "success");
						setSubmitting(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	return (
		<div>
			{databyEmail && databyEmail?.role === "Admin" && (
				<Button
					className='button border'
					onClick={() => handleDelete(params?.row?._id)}
					sx={{
						fontWeight: "bold",
						border: "2px solid",
						backgroundColor: "transparent",
						borderRadius: "25px",
						m: 0.5,
					}}
					variant='contained'>
					<DeleteIcon />
				</Button>
			)}
		</div>
	);
};

export default DeleteFunction;
