import {
	CircularProgress,
	Backdrop,
	Button,
	Grid,
	TextField,
	Typography,
	Container,
	Box,
	Divider,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPI } from "../../../../apiContext";

const Referrel = () => {
	const { data, info } = useAPI();
	const [infos, setInfos] = useState();
	const [submitting, setSubmitting] = useState(false);
	useEffect(() => {
		axios
			.get(`https://americasolarpros.herokuapp.com/RefferelsDetails`)
			.then((res) => {
				setInfos(res.data);
			});
	}, [submitting]);
	const { register, handleSubmit, reset } = useForm();
	const onSubmit = (infos) => {
		const datas = {
			id: `Referrel${Math.floor(100000 + Math.random() * 900000)}`,
			...infos,
			cardUsed: data?.url,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
			status: "Pending",
		};
		console.log(datas);
		setSubmitting(true);
		axios
			.post(`https://americasolarpros.herokuapp.com/Refferels`, datas)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Submitted",
					showConfirmButton: false,
					timer: 1500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<Container sx={{ pt: 5 }}>
			{!infos?.details?.title === "off" ? (
				""
			) : (
				<>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Typography
							className='textColor'
							sx={{ fontWeight: 900 }}
							variant='h5'
							component='div'>
							{infos?.details?.title}
							<Divider className={`${info?.hr}`} />
						</Typography>
						<Typography
							className='textColor'
							sx={{
								fontWeight: 900,
								display: { md: "block", xs: "none" },
								pb: 1,
								whiteSpace: "pre-line",
								textAlign: "center",
							}}
							variant='h6'
							component='div'>
							{infos?.details?.offers}
						</Typography>
					</Box>
					<form onSubmit={handleSubmit(onSubmit)} method='post'>
						<Grid container spacing={2}>
							<Grid item md={6} xs={12}>
								<TextField
									size='small'
									required
									sx={{ width: "100%", my: 0.7 }}
									id='outlined-basic'
									name='referralsName'
									label='Referrals Name'
									{...register("referralsName", { required: true })}
								/>
								<TextField
									size='small'
									sx={{ width: "100%", my: 0.7 }}
									id='outlined-basic'
									name='referralsEmail'
									type='email'
									label='Referrals Email'
									{...register("referralsEmail")}
								/>
								<TextField
									size='small'
									required
									sx={{ width: "100%", mt: 0.7 }}
									id='outlined-basic'
									name='referralsNumber'
									type='tel'
									label='Referrals Number'
									{...register("referralsNumber", { required: true })}
								/>
							</Grid>
							<Typography
								className='textColor'
								sx={{
									fontWeight: 900,
									display: { md: "none", xs: "block" },
									pt: 1,
									whiteSpace: "pre-line",
									textAlign: "center",
									mx: "auto",
								}}
								variant='h6'>
								{infos?.details?.offers}
							</Typography>
							<Grid item md={6} xs={12}>
								<TextField
									size='small'
									required
									sx={{ width: "100%", my: 0.7 }}
									id='outlined-basic'
									name='referrerName'
									label='Referrer Name'
									{...register("referrerName", { required: true })}
								/>
								<TextField
									size='small'
									required
									sx={{ width: "100%", my: 0.7 }}
									id='outlined-basic'
									name='referrerEmail'
									type='email'
									label='Referrer Email'
									{...register("referrerEmail", { required: true })}
								/>
								<TextField
									size='small'
									required
									sx={{ width: "100%", mt: 0.7 }}
									id='outlined-basic'
									name='referrerNumber'
									type='tel'
									label='Referrer Number'
									{...register("referrerNumber", { required: true })}
								/>
							</Grid>
							<Grid item md={12} xs={12}>
								<TextField
									size='small'
									required
									multiline
									rows={2}
									sx={{ width: "100%" }}
									id='outlined-basic'
									name='note'
									label='Note'
									{...register("note", { required: true })}
								/>
							</Grid>
							<Grid item md={12} xs={12}>
								<Button
									className='sendButton'
									type='submit'
									variant='contained'
									sx={{
										width: "100%",
										mb: 2,
										px: 3,
										fontWeight: "bold",
										borderRadius: "25px",
									}}>
									Submit
								</Button>
							</Grid>
						</Grid>
					</form>
					<Backdrop
						sx={{
							color: "#fff",
							zIndex: (theme) => theme.zIndex.drawer + 1,
						}}
						open={submitting}>
						<CircularProgress color='inherit' />
					</Backdrop>
				</>
			)}
		</Container>
	);
};

export default Referrel;
